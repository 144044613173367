.modal-header {
  background-color: #CDCDCD;
  /*  padding: 15px;*/
  background-image: -moz-linear-gradient(top, #CDCDCD, #AAAAAA);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#CDCDCD), to(#AAAAAA));
  background-image: -webkit-linear-gradient(top, #CDCDCD, #AAAAAA);
  background-image: -o-linear-gradient(top, #CDCDCD, #AAAAAA);
  background-image: linear-gradient(to bottom, #CDCDCD, #AAAAAA);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffAAAAAA', endColorstr='#ffCDCDCD', GradientType=0);

  border: 1px solid #BBB;
  -webkit-box-shadow: inset 0 1px 0 rgba(75, 75, 75, 0.25);
  -moz-box-shadow: inset 0 1px 0 rgba(75, 75, 75, 0.25);
  box-shadow: inset 0 1px 0 rgba(75, 75, 75, 0.25);

  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.modal-footer {
  padding: 14px 15px 15px;
  margin-bottom: 0;
  margin-top: 0;
  text-align: right;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
  -webkit-box-shadow: inset 0 1px 0 #fff;
  -moz-box-shadow: inset 0 1px 0 #fff;
  box-shadow: inset 0 1px 0 #fff;
}

.modal-header h3 {
  color: #333;
}

.modal-header a {
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: #000000;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  filter: alpha(opacity=50);
  margin-left: 10px;
}

.modal-header a:hover,
.modal-header a:focus,
.modal-header .close:hover,
.modal-header .close:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.modal-header a.help .fa-check-circle {
  color: #ff9900;
}

.modal-header button,
.modal-header .button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.fatter-modal {
  width: 700px;
  padding: 10px;
}

.modal .modal-body {
  overflow: visible;
}

.modal .modal-body .modal-close-btn {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 16px;
}

.modal .modal-body .modal-close-btn .fa.fa-times {
  font-size: 18px;
  color: var(--gray-600);
}

.modal-header button a {
  color: inherit;
  text-decoration: inherit;
}

.login-modal .modal-dialog {
  top: 30%;
}

.login-modal .modal-dialog .modal-content {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.project-modal .modal-dialog {
  width: 760px;
  left: 50%;
  margin-left: -380px;
}

.full-screen-modal .modal-dialog {
  width: 90%;
  left: 50%;
  margin-left: -45%;
}

.full-screen-modal .modal-dialog .modal-content {
  width: 100%;
}

.scenario-modal .modal-dialog {
  width: 80%;
  left: 50%;
  margin-left: -40%;
}

.scenario-modal .modal-dialog .modal-content {
  width: 100%;
}

.temp-modal .modal-dialog {
  width: 660px;
  left: 50%;
  margin-left: -330px;
}

.documentation-modal .modal-dialog {
  width: 60%;
  left: 50%;
  margin-left: -30%;
}

.documentation-modal .modal-dialog .modal-content {
  padding: 15px;
}


.optimization-modal {
  padding-left: 300px;
}

.optimization-modal .modal-dialog {
  top: 26px;
  width: 96%;
  left: 50%;
  margin-left: -48%;
}

.optimization-modal .modal-dialog .modal-header {
  padding: 10px 20px;
  background-color: #CDCDCD;
  box-shadow: none;
  background-image: none;
  border: none;
}

.optimization-modal .modal-dialog .modal-content {
  width: 100%;
}

.centered-modal .modal-content {
  margin-top: 50%;
}

/*.full-screen-modal.modal.fade.in {
  top: 10%;
}

.temp-modal.modal.fade.in {
  top: 15%;
}

.scenario-modal.modal.fade.in {
  top: 10%;
}
*/

.not-activated-modal .modal-dialog {
  width: 520px;
  left: 50%;
  margin-left: -260px;
}

.activated-modal .modal-dialog {
  width: 690px;
  left: 50%;
  margin-left: -345px;
}

.maps-login-modal h1 {
  color: #f90;
  font-size: 32px;
  margin-bottom: .4em;
}

.maps-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 24px;
}

.maps-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.maps-modal-details {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.maps-modal-btns {
  display: flex;
  width: stretch;
  justify-content: flex-end;
  gap: 8px;
}


.paywall-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.paywall-modal-btns {
  display: flex;
  width: stretch;
  justify-content: flex-end;
  gap: 8px;
}

.paywall-modal-details {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.paywall-modal-image {
  width: 100%;
}

.paywall-modal-image img {
  width: 100%;
  object-fit: contain;
}

.paywall-designer-dropdown-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove-independent-tilt-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.remove-independent-tilt-modal-details {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}


.remove-independent-tilt-modal-btns {
  display: flex;
  width: stretch;
  justify-content: flex-end;
  gap: 8px;
}


.expiration-modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 28px 12px;
}

.expiration-modal .expiration-modal-title {
  color: var(--blue-gray);
  font-weight: 700;
}

.expiration-modal .expiration-modal-sub-title {
  font-size: 18px;
  color: #4c4c4c;
  width: 80%;
}

.expiration-modal .expiration-modal-body {
  width: stretch;
  display: flex;
  justify-content: flex-start;
  gap: 24px;
}

.expiration-modal .expiration-modal-body .expiration-modal-body-card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  gap: 16px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  padding: 35px 24px;
  box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 8%);
}

.expiration-modal-btn {
  font-size: 16px;
  box-shadow: 0px -1px 1px 0px rgb(16 22 26 / 10%) inset;
  border-radius: 3px;
  padding: 12px 10px;
  font-weight: 600;
}

.expiration-modal-signup {
  width: max-content;
}

.expiration-modal-btn-wrapper {
  position: absolute;
  right: 0px;
  bottom: 30px;
  width: stretch;
}

.expiration-modal .expiration-modal-footer {
  display: flex;
  gap: 24px;
  padding: 0px 24px;
}

.expiration-modal-footer .footer-text {
  width: 50%;
}

.expiration-modal-footer .footer-text .plan-title {
  color: #000000;
  border: 0px;
  font-size: 22px;
  color: #607D8B;
}

.expiration-modal-footer .footer-text div {
  font-size: 14px;
}

.expiration-modal-footer .footer-form {
  width: 50%;
}

.expiration-modal-footer .footer-form a {
  margin-top: 28px;
}

.expiration-modal-footer .btn-default {
  opacity: 1;
  color: #1C2127;
  box-shadow: 0px -1px 1px 0px rgba(16, 22, 26, 0.1) inset;
  background: #F6F7F9;
}

.expiration-modal-footer .btn-default[disabled] {
  color: #1C2127;
  box-shadow: 0px -1px 1px 0px rgba(16, 22, 26, 0.1) inset;
  background: #F6F7F9;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.plan-card {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.plan-card-title {
  font-size: 24px;
  line-height: 32px;
  color: #607D8B;
}

.plan-card-price {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 30px;
  line-height: 20px;
  color: #303030;
}

.plan-card-price span.description {
  font-size: 14px;
  line-height: 20px;
}

.plan-card-price span.note {
  line-height: 20px;
}

.plan-card-price span.term {
  font-size: 14px;
  line-height: 20px;
}

.plan-card-price span.footnote {
  display: flex;
  flex-direction: column;
  line-height: 16px;
  font-size: 14px;
  gap: 4px;
}

.plan-card-features {
  display: flex;
  flex-direction: column;
}

.plan-card-features span {
  font-size: 16px;
  line-height: 22px;
}

.line {
  border: 1px solid rgba(229, 232, 235, .8);
}

.billing-redirect-modal {
  display: flex;
  flex-direction: column;
}

.billing-redirect-modal .billing-redirect-modal-title {
  color: var(--blue-gray);
  font-weight: 700;
}
