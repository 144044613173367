/*------------------------------------------------------------------

[Responsive Stylesheet]

Project:	Base Admin
Version:	3.0
Last change:	09/04/2013
Assigned to:	Rod Howard (rh)


[Table of contents]


*** Any customizations made to this should be added to the custom.css
stylesheet in order to separate your customizations and make
upgrading in the future easier. ***

-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[1. Max Width: 480px]
*/
@media (max-width: 480px) {
  .error-container h1 {
    font-size: 72px;
  }
  .shortcuts .shortcut {
    width: 95px;
  }
  .shortcuts .shortcut .shortcut-label {
    font-size: 12px;
  }
  .shortcuts .shortcut .shortcut-icon {
    font-size: 24px;
  }
}
/*------------------------------------------------------------------
[3. Max Width: 979px]
*/
@media (max-width: 979px) {
  .navbar-fixed-top {
    position: static;
    margin-bottom: 0;
  }
  .navbar .btn-navbar {
    font-size: 18px;
    background: none;
    filter: none;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .navbar .container {
    padding: 0 10px;
  }
  .subnavbar .subnavbar .container {
    width: auto;
  }
}
/* Extra small devices (phones, up to 480px) */
/* No media query since this is the default in Bootstrap */
@media (max-width: 768px) {
  .navbar {
    padding-left: 10px;
    padding-right: 10px;
    margin-right: -20px;
    margin-left: -20px;
  }
  .navbar .navbar-nav > li > .dropdown-menu::before,
  .navbar .navbar-nav > li > .dropdown-menu::after {
    display: none;
  }
  .subnavbar .subnav-toggle {
    display: block;
  }
  #main {
    padding: 0 10px;
    margin-right: -20px;
    margin-left: -20px;
  }
  .extra {
    margin-right: -20px;
    margin-left: -20px;
  }
  .main .container {
    padding: 0;
  }
  .main .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .footer {
    margin-right: -20px;
    margin-left: -20px;
  }
  .footer #footer-terms {
    margin-top: 1em;
    text-align: left;
  }
  .footer #footer-terms a {
    margin-left: 0;
    margin-right: 1em;
  }
  .item-row {
    display: block;
  }
  .item-row:before,
  .item-row:after {
    content: " ";
    /* 1 */

    display: table;
    /* 2 */

  }
  .item-row:after {
    clear: both;
  }
  .item-row .item-label {
    display: block;
    width: 100%;
    margin-bottom: 1.5em;
  }
  .item-row .item-content {
    display: block;
    width: 100%;
  }
  .subnavbar {
    margin-left: -20px;
    margin-right: -20px;
  }
  .subnavbar .btn-subnavbar {
    display: block;
  }
  .subnavbar .subnavbar-inner {
    height: auto;
  }
  .subnavbar .mainnav {
    display: block;
    width: 100%;
    height: auto;
    margin-top: 1em;
    margin-bottom: 1.5em;
    border: none;
  }
  .subnavbar .mainnav > li {
    float: none;
    width: 100%;
    height: auto;
    margin-bottom: 1em;
    text-align: left;
    border: none;
  }
  .subnavbar .mainnav > li > a {
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: .5em;
    color: #CCC;
    font-size: 13px;
    font-weight: 600;
  }
  .subnavbar .mainnav > li > a > span {
    display: inline;
  }
  .subnavbar .mainnav > li > a > i {
    display: inline;
    margin-right: .5em;
    margin-bottom: 0;
    font-size: 14px;
  }
  .subnavbar .mainnav > li > a .caret {
    margin-top: -2px;
    margin-left: 3px;
  }
  .subnavbar .mainnav > li.active > a {
    color: #F90;
    background: transparent;
  }
  .subnavbar .dropdown .dropdown-menu {
    position: static;
    float: none;
    width: 100%;
    background: transparent;
    border: none;
    box-shadow: none;
  }
  .subnavbar .dropdown .dropdown-menu a {
    padding: 12px 40px;
    color: #FFF;
    font-weight: 600;
  }
  .subnavbar .dropdown .dropdown-menu a:active,
  .subnavbar .dropdown .dropdown-menu a:focus {
    background: #555 !important;
    outline: none;
  }
  .subnavbar .dropdown .dropdown-menu::before,
  .subnavbar .dropdown .dropdown-menu::after {
    display: none;
  }
  .subnavbar .dropdown.open .caret {
    display: inline-block;
  }
  .subnavbar .dropdown-submenu > a:after {
    display: none;
  }
  .subnavbar .dropdown-submenu > .dropdown-menu {
    display: block;
  }
  .subnavbar .dropdown-submenu > .dropdown-menu > li > a {
    padding-right: 20px;
    padding-left: 60px;
  }
}
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .subnav-collapse.collapse {
    display: block!important;
    height: auto!important;
    padding-bottom: 0;
    overflow: visible!important;
  }
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {

}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .navbar .container,
  .subnavbar .container {
    padding-right: 0;
    padding-left: 0;
  }
  .page-title {
    margin-left: -15px;
  }
}
