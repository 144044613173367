
/*------------------------------------------------------------------

[Login/Signup Stylesheet]

  Project:    Base Admin
  Version:    1.0
  Last change:  05/21/2012
  Assigned to:  Rod Howard (rh)

-------------------------------------------------------------------*/




/** Base Body Styles **/
/* tk body{ color:#838383; font: 13px/1.7em 'Open Sans';}*/


.account-container {
  max-width: 390px;
  display: block;
  margin: 60px auto 0 auto;

  border: 1px solid #d5d5d5;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}


.login-action {
  width: 100%;
  margin-top: 18px;
}

.left {
  float: left;
}

.right {
  float: right;
}

.login-fields .field {
  margin-bottom: 1.25em;
}


.login-fields input {
  font-family: 'Open Sans';
  font-size: 13px;
  color: #8e8d8d;
  padding: 11px 15px 10px 50px;
  background-color: #fdfdfd;
  width: 100%;
  display: block;
  margin: 0;
  box-shadow: inset 2px 2px 4px #f1f1f1;
  height: 40px;
}

.login-actions {
  float: left;

  width: 100%;

  margin-top: -1em;
  margin-bottom: 1.25em;
}

.login-social {
  float: left;

  padding: 10px 0 15px;

  border: 1px dotted #CCC;
  border-right: none;
  border-left: none;
}

.login-actions .checkbox {
  position:absolute;
  display:inline-block;
  margin-top:15px;
}

.login-actions .checkbox label {
  display:inline-block;
  max-width:150px;
}

.login-actions .checkbox input[type='checkbox'] {
  box-shadow:none;
  width:30px;
}

/** Text Under Box**/
.login-extra {
  display: block;
  width: 300px;
  margin: 1.5em auto;

  text-align: center;
  line-height: 19px;

  text-shadow: 1px 1px 0px #fff;
}


.account-container h1 {
  margin-bottom: .4em;

  color: #f90;

  font-size: 30px;
  font-weight: 300;
}

/** Buttons **/
.twitter, .fb {
  position: relative;

  height: 32px;
  width: 157px;
  display: block;

  background: url(../../img/signin/twitter_btn.png) no-repeat;

}

.fb {
  width: 162px;

  background: url(../../img/signin/fb_btn.png) no-repeat;
}

.twitter:active, .fb:active {
  top: 1px;
}

.twitter:hover, .fb:hover {
  background-position: 0 -32px;
}

.twitter a, .fb a {
  padding: 5px 0 0 35px;
  text-shadow: -1px -1px 0px rgba(0,0,0,.3);
  color:#fff;
  font-weight: bold;
  font-size: 11px;
  height: 32px;
  display: block;
}

.fb a {
  padding: 5px 0 0 31px;

}

.twitter, .fb {
  display: inline-block;
}

.twitter a:hover, .fb a:hover {
  color: #FFF;
  text-decoration: none;
}








.register .login-social {
  margin-bottom: 1em;
}

.register .login-actions {
  margin-bottom: 0;
}

.register .login-fields input {
  width: 299px;
  padding-left: 6px;
}

.register h1 {
  color: #444;
}


@media (max-width: 480px) {

  .account-container {
    width: 280px;
    margin-top: 35px;
  }

  .login-fields input {
    width: 160px;
  }

  .login-social {
    width: 100%;
  }

  .twitter {
    display: block;
    margin-bottom: 1em;
  }

  .register .login-fields input {
    width: 204px;
    padding-left: 6px;
  }

}
