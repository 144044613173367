
/* better radio button alignment*/
td input[type="radio"], td input[type="checkbox"] {
  margin: 2px 0 0;
}

.input-group-addon {
  padding:6px;
}
/* undo stupid margins*/
.form-group {
  margin-bottom:15px;
}


.form-group .errors{
  margin-bottom: -24px;
}

.form-horizontal .form-group .errors {
  margin-bottom: -16px;
}
.form-horizontal .form-group span.error {
  margin-left: 5px;
}

/* recreate errors */
.form-group span.error {
  display:inline;
  font-size: 11px;
  font-weight: 600;
  position: relative;
  top: 4px;
  padding: 0 4px;
  /*margin-bottom: 1em;*/
  color: #FFF;
  background: #B94A48;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.35);
}

.form-group span.error::after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #B94A48;
  border-top:none;
  position: absolute;
  top: -6px;
  left: 7px;
  bottom:inherit;
}

.form-actions {
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 0px;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
}


.modal .errors {
  width:300px;
  overflow:visible;
}


/*.form-group.error {
  padding-bottom: .5em;
}
*/


/* Remove focus HTML5 errors */
/*
input:focus:invalid,
textarea:focus:invalid,
select:focus:invalid,
input:focus:invalid:focus,
textarea:focus:invalid:focus,
select:focus:invalid:focus  {
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  outline: thin dotted \9;
  //IE6-9

  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  z-index: 2;
  color:inherit;
}

.control-group.error input:focus,
.control-group.error select:focus,
.control-group.error textarea:focus {
  border-color: #953b39;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
  color: #953b39;
}

*/

form .help-text {
  color: #999;
  font-size: 0.9em;
}
