/*------------------------------------------------------------------

[Dashboard Stylesheet]

	Project:		Base Admin
	Version:		1.0
	Last change:	05/21/2012
	Assigned to:	Rod Howard (rh)



[Table of contents]

	1. Shortcuts
	2. Stats
	3. Task List
	4. News Item

-------------------------------------------------------------------*/







/*------------------------------------------------------------------
[2. Stats / .stats]
*/

.stats {
	width: 100%;
	display: table;
	padding: 0 0 0 10px;
	margin-top: .5em;
	margin-bottom: 1.9em;
}

.stats .stat {
	display: table-cell;
	width: 40%;
	vertical-align: top;

	font-size: 11px;
	font-weight: bold;
	color: #999;
}

.stat-value {
	display: block;
	margin-bottom: .55em;

	font-size: 30px;
	font-weight: bold;
	letter-spacing: -2px;
	color: #444;
}

.stat-time {
	text-align: center;
	padding-top: 1.5em;
}

.stat-time .stat-value {
	color: #F90;
	font-size: 40px;
}

.stats #donut-chart {
	height: 100px;
	margin-left: -20px;
}





/*------------------------------------------------------------------
[3. News Item / .news-items]
*/

.news-items {
	padding: 0;
	margin: 1em 0 0;
}

.news-items li {
	display: table;
	padding: 0 2em 0 1.5em;
	padding-bottom: 1em;
	margin-bottom: 1em;

	border-bottom: 1px dotted #CCC;
}

.news-items li:last-child { padding-bottom: 0; border: none; }

.news-item-date {
	display: table-cell;
}

.news-item-detail {
	display: table-cell;
}

.news-item-title {
	font-size: 13px;
	font-weight: 600;
}

.news-item-date {
	width: 75px;
	vertical-align: middle;

	text-align: right;
}

.news-item-day {
	display: block;
	margin-bottom: .25em;

	font-size: 24px;
	color: #888;
}

.news-item-preview {
	margin-bottom: 0;

	color: #777;
}

.news-item-month {
	display: block;
	padding-right: 1px;

	font-size: 12px;
	font-weight: 600;
	color: #888;
}



/*------------------------------------------------------------------
[4. Action Table / .action-table]
*/

.action-table .btn-small {
	padding: 4px 5px 5px;

	font-size: 10px;
}

.action-table .td-actions {
	width: 80px;

	text-align: center;
}

	.action-table .td-actions .btn {
		margin-right: .5em;
	}

	.action-table .td-actions .btn:last-child {
		margin-rigth: 0;
	}







@media (max-width: 979px) {

}


@media (max-width: 480px) {

	.stats .stat {

		margin-bottom: 3em;
	}

	.stats .stat .stat-value {
		margin-bottom: .15em;

		font-size: 20px;
	}

	.stats {
		float: left;

		display: block;

		margin-bottom: 0;
	}

	#chart-stats {
		margin: 2em 0 1em;
	}
}
