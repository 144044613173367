.map-overlay-container {
  position: absolute;
  color: transparent;
}

.map-overlay-container > li > .dropdown-menu:before {
  position: absolute;
  top: -7px;
  left: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: #fff;
  content: '';
}

.map-overlay-container .dropdown-menu  li > a {
  cursor:pointer;
}

.crosshair, .crosshair * {
  cursor: crosshair !important;
}

.map-overlay-container .dropdown .dropdown-menu  {
  display:block;
}

.scaled-map-tile img {
  width: 100%;
  height: 100%;
  visibility: none;
}

.scaled-map-tile img.loaded {
  visibility: visible;
}


.map-tooltip.tooltip {
  display: inline-block;
  margin: 0;
  opacity: 1;
  cursor: pointer;
}

.map-tooltip.tooltip .tooltip-inner{
  padding: 2px 6px;
  font-size: inherit;
}
