.input-group-btn-width {
  width: 20%;
}

.dropdown-button {
  height: 40px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
}

.bc-phone-number-dropdown-menu {
  overflow-y: scroll;
  max-height: 10em;
}

.bc-phone-number-country-anchor {
  padding-left: 1em;
}

.bc-phone-number-country-icon {
  margin-right: 1em;
}

.bc-phone-number-flag {
  margin-right: 0.5em;
}
