body.pdf .container,
body.pdf .navbar-static-top .container,
body.pdf .navbar-fixed-top .container,
body.pdf .navbar-fixed-bottom .container,
body.pdf .full-screen-modal.modal .modal-content {
  width: 1200px;
  /*margin:80px;*/
  /*margin-left:60px;*/
  /*margin-right:100px;*/
}

body.pdf .navbar .navbar-right {
    position:absolute;
    bottom:20%;
    right:40px;
}

.pdf .col-xs-7 .chart-holder {
  /*width:630px;*/
}

.pdf .col-xs-5 .chart-holder {
  /*width:435px;*/
}




/*body.pdf .widget-header {
  background-color:#CCC;
}
*/
body.pdf {
    background: transparent !important;
    font: 12px/1.5em "Open Sans", "Helvetica Light",Helvetica,Arial,sans-serif;

    /* disable all animations when rendering */

    /*CSS transitions*/
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    /*CSS transforms ->> Removing These may burn us for renders*/
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
}

body.pdf .hidden-print,
body.pdf tr.hidden-print,
body.pdf th.hidden-print,
body.pdf td.hidden-print,
body.pdf .ng-hide {
    display: none!important;
}

body.pdf .widget .widget-content  {
  margin-top:-3px;
}

body.pdf .printable-container {
  padding-left: 30px;
  padding-right: 40px;
}

@media print {
  .background {

  }
  .widget {
      display:inline-block;
      width:100%;
      margin-bottom:20px!important;
  }
  .widget:after {
      content: '';
      display:block;
      height:0px;
      visibility: hidden;
  }

  .navbar {
    margin-bottom: 20px;
  }
  .navbar .navbar-right a{
    color:#fff!important;
    text-shadow:none;
  }

  .subnavbar, .extra, .sidebar {
      display: none;
  }

  .widget {
    page-break-inside: avoid;
  }

  .stacked:after, .stacked:before{
    display:none;
  }

  .nav .dropdown .caret {
    display:none;
  }

  .widget, .widget-header, .widget-content {
    border-color:#BBB !important;

  }

  .widget-table th {
    background-image:none !important;
    background-repeat:none !important;
    background-color:#DDD !important;
  }

}

/* bootstrap print media overrides*/

@media print {
  * {
    /*color: #000 !important;*/
    text-shadow: none !important;
    /*background: transparent !important;*/
    box-shadow: none !important;
  }

  a,
  a:visited,
  a.clickable,
  a.clickable:hover {
    text-decoration: none!important;
    color: inherit !important;
    cursor: inherit !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page  {
    margin: 1.5cm 0.333cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  select {
    background: #fff !important;
  }

  .widget .table th {
    background-color: #eee !important;
  }

  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }
  .label {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }

  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
  .hidden-print,
  tr.hidden-print,
  th.hidden-print,
  td.hidden-print {
    display: none !important;
  }
}
