
.new-scenario .tab-content {
  overflow-x:hidden;
}



.form-horizontal .multiline-label {
    margin-top: -5px;
}


.form-horizontal .multiline-controls {
    margin-left: -15px;
    margin-top: 8px;
}


p.form-horizontal-comment {
    padding-left:30px;
    margin:-10px 0 20px 0;
}



table.project-table {
}


.project-table .project-column {
  word-wrap: break-word;
  text-align:left;
  padding-left:10px;
}

@media (min-width: 1200px) { .project-table .project-column {
    max-width: 480px;
}}

@media (max-width: 1199px) { .project-table .project-column {
    max-width: 320px;
}}

@media (max-width: 979px) { .project-table .project-column {
    max-width: 220px;
}}

@media (max-width: 768px) { .project-table .project-column {
    max-width: 180px;
}}
@media (max-width: 480px) { .project-table .project-column {
    max-width: 100px;
}}

.project-table .star-column,
.project-table .archive-column {
    font-size: 16px;
    width: 46px;
}

.project-table h5 {
    margin:5px;
}

.project-table h5 small {
    font-size:12px;
    line-height:14px;
}

.project-table th,
.project-table td {
    text-align:center;
}

.project-table .value {
    font-size: 26px;
    color:#999;
    font-weight:200px;
}


/*
  We're using CSS transitions for when
  the enter and move events are triggered
  for the element that has the .repeated-item
  class
*/
.repeated-item.ng-enter, .repeated-item.ng-move, .repeated-item.ng-leave {
  -webkit-transition:0.6s linear all;
  -moz-transition:0.6s linear all;
  -o-transition:0.6s linear all;
  transition:0.6s linear all;
}
.repeated-item.ng-enter, .repeated-item.ng-move {
  opacity:0;
}
.repeated-item.ng-leave {
  opacity:1;
}

/*
 The ng-enter-active and ng-move-active
 are where the transition destination properties
 are set so that the animation knows what to
 animate.
*/
.repeated-item.ng-enter.ng-enter-active,
.repeated-item.ng-move.ng-move-active {
  opacity:1;
}

.repeated-item.ng-leave.ng-leave-active {
  opacity:0;
}

.form-dropdown label{
    width:100%;
    height:100%;
    padding: 5px 9px;
}

.form-dropdown li {
    padding:0 5px;
}
.form-dropdown li a {
    padding: 0;
    margin: 0;
}

.form-dropdown label {
    margin: 0;
}


.form-dropdown .dropdown-header:hover{
    background-color:inherit;
    color:inherit;
    text-shadow:inherit;
}

.table-condensed td div.radio {
  margin-top:-4px;
}

.financials hr {
  margin-top:0;
  margin-bottom:10px;
}

.rate-table tr.update-all td,
.rate-table td.update-all {
  background-color: #FC3!important;
}
