.payment-table {
    margin-top:-15px;
}

.table.payment-table tr th,
.table.payment-table tr td {
    border:none;
}

.payment-table .right {
    text-align: right;
}

.payment-table .left {
    text-align: left;
}

.payment-table .total {
    font-size:110%;
}


.activated-modal .btn-info .help-text {
  color: white;
  opacity: 0.5;
  font-size: 13px;
  line-height: 15px;
}

.activated-modal .saving-status {
  height: 32px;
}

.activated-modal .saving-status p {
  line-height: 18px;
}

.activated-modal p.help-text {
  line-height: 16px;
}

.activated-modal .training .help-text {
  margin-top: 3px;
}

.enphase .login-fields input {
  width: 100%;
}



.team-select2 .select2-result-label span {
  font-size: 12px;
  color: #666;
  line-height:1em;
}
