.docs .sidebar {
  -webkit-transition: all 0.75s ease;
  -moz-transition: all 0.75s ease;
  -o-transition: all 0.75s ease;
  transition: all 0.75s ease;
}

.docs .sidebar .nav > li > a {
    padding:2px 15px;
}

/* Documentation stylesheet*/
.nav-list > .active > a, .nav-list > .active > a:hover, .nav-list > .active > a:focus {
    background-color:#F90;
}


.docs .thumbnail {
    background-color: whitesmoke;

}
.docs .thumbnail img {
    max-height:250px;
}
.docs .thumbnail  > h5, .docs .thumnail > p,.docs .thumbnail > a {
    text-align:center;
    margin-bottom:0;
}

.docs a.source {
    text-align: right;
}


.documentation-floater {
    position: fixed;
    top: 85px;
}

.chapter {
    padding-top:5px;
    margin-bottom:25px;
    border-bottom: 2px solid black;
}
.chapter .title{
    font-size:155%;
    font-weight:bold;
    padding-bottom:10px;
}

/* Legal docs */
.chapter-legal {
    padding-top: 10px;
    padding-bottom: 25px;
}
.chapter-legal .header {
    font-weight: 600;
}
.chapter-legal .title {
    font-size: 155%;
    font-weight: 600;
    padding-bottom: 6px;
}
.chapter-legal h2,
.chapter-legal .subtitle {
    font-size: 130%;
    font-weight: 600;
}
.chapter-legal .clause {
    text-align: center;
    font-style: italic;
}
.chapter-legal .clause p:first-child {
    margin-bottom: 20px;
}
.chapter-legal-h1 {
    margin: 10px 0;
}
/* Lists */
.chapter-legal ol,
.chapter-legal ul.dashed,
.chapter-legal.numbered ol {
    list-style-type: none;
    display: table;
    border-spacing: 0 10px;
    padding-left: 0;
}
.chapter-legal ol > li,
.chapter-legal ul.dashed > li,
.chapter-legal.numbered ol > li {
    display: table-row;
}
.chapter-legal ol > li::before,
.chapter-legal ul.dashed > li::before,
.chapter-legal.numbered ol > li::before {
    display: table-cell;
}
.chapter-legal ol > li,
.chapter-legal ul:not(.unstyled) > li {
    padding: 6px 0;
}
.chapter-legal li::before {
    padding-right: 12px;
}
.chapter-legal li p:last-child {
    margin-bottom: 0;
}
.chapter-legal ul {
    list-style-type: disc;
}
/* Custom lists */
.chapter-legal ol {
    counter-reset: listSection;
}
.chapter-legal ol > li {
    counter-increment: listSection;
}
.chapter-legal ol > li::before {
    content: counter(listSection) ". ";
}
.chapter-legal ol.alpha li::before {
    content: "(" counter(listSection, lower-alpha) ") ";
}
.chapter-legal ol.roman li::before {
    content: "(" counter(listSection, lower-roman) ") ";
}
.chapter-legal ul.dashed li::before {
    content: "— ";
}
/* Ordered lists with sublists */
.counter-start {
    counter-reset: section;
}
.chapter-legal.numbered {
    counter-increment: section;
    padding: 10px 0;
}
.chapter-legal.numbered h2::before,
.chapter-legal.numbered .header::before,
.chapter-legal.numbered .subtitle::before,
.chapter-legal.numbered .title::before {
    content: counter(section) ". ";
    padding-right: 6px;
}
.chapter-legal.numbered ol {
    counter-reset: subsection;
}
.chapter-legal.numbered ol > li::before {
    counter-increment: subsection;
    content: counter(section) "." counters(subsection, ".") " ";
}
.chapter-legal.numbered h2::before,
.chapter-legal.numbered ol li::before {
    padding-right: 12px;
    font-weight: 600;
}

.section{
    padding-top:5px;
    border-bottom:1px solid #d3d3d3;
    padding-bottom:5px;
    margin-bottom:5px;
}
.section .title{
    font-size:130%;
    font-weight:normal;
    padding-bottom:5px;
}
.subsection {
    margin-left:3%;
    border-bottom:none;
    padding-bottom:3px;
    width:97%;
}
.subsection .title{
    margin-left:-3%;
    font-size:115%;
    padding-bottom:0;
    padding-top:5px;
}

.subsubsection .title{
    font-size:110%;
    font-style: italic;
    padding-bottom:0;
    padding-top:3px;
}

.docs table, .docs dl {
    background-color:#FdFdFd;
    border: solid 1px lightgray;
}

.docs table dl {
    background-color:inherit;
    border: inherit;
}

.parameter-table {
    vertical-align:middle;
}

.parameter-table th{
    text-align:right;
    padding-right:5px;
    border-right:2px solid #d3d3d3;
    width:100px;
    margin-bottom:3px;
}
.parameter-table td{
    min-width:125px;
    padding-left:20px;
}


.parameter-table dl{
    margin:0;
}

.parameter-table dt{
    width:80px;
    text-align:center;
}

.parameter-table dd{
    margin-left:70px;
    margin-top:5px;
    margin-bottom:5px;
}

/*.sidebar-nav {
   padding: 9px 0 !important;
   min-width:120px;
}
*/
.documentation-floater {
    position: fixed;
    top: 145px;
}


.docs .sidenav > li > a{
    color:#636363;
}


.faq-list code {
    color:#428bEE;
    background-color:#EEE;
}
