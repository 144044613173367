body.pdf .designer-content{
    top:0!important;
}

body.pdf .map-overlay  {
    display:none;
}

body.pdf .ui-pnotify {
    display:none!important;
}

body.pdf #logo-overlay {
    position: absolute;
    width: 20%;
    height: 15%;
    left: 2.5%;
    top: 82.5%;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: contain;
    z-index:99999;
}
