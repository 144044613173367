/*------------------------------------------------------------------

[Main Stylesheet]

Project:	Base Admin
Version:	3.0
Last change:	09/04/2013
Assigned to:	Rod Howard (rh)


*** Any customizations made to this should be added to the custom.css
stylesheet in order to separate your customizations and make
upgrading in the future easier. ***

-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[ Global ]
*/

/* MADHAX - using absolute path here because of concatenation :( */
#helioscope {
    background: #e9e9e9 url("../img/tiny_grid.png") repeat 0 0;
    font: 13px/1.7em 'Open Sans';
}

p {
  /*font: 13px/1.7em 'Open Sans';*/
}
h1,
h2,
h3,
h4,
h5,
h6 {
  /*font-family: 'Open Sans';*/
  font-weight: normal;
}
input,
button,
select,
textarea {
  /*font-family: 'Open Sans';*/
}
[class^="fa-"]:not(.ui-icon),
[class*="fa-"]:not(.ui-icon) {
  background: none;
}
[class^="fa-"] {
  background: none\9;
}
.dropdown .dropdown-menu {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.btn-icon-only {
  padding-right: 6px;
  padding-left: 3px;
}
.table td {
  vertical-align: middle;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}
.chart-holder {
  width: 100%;
  height: 250px;
}
.clear {
  clear: both;
}
/*------------------------------------------------------------------
[ Navbar / .navbar ]
*/
.navbar {
  margin-bottom: 0;
  border-radius: 0;
  border-radius: 0 !important;
}
.navbar.navbar-inverse {
  background: #292929;
  border-color: #292929;
  border-bottom: 1px solid #000;
}
.navbar.navbar-inverse .navbar-toggle {
  padding: 9px;
  margin-top: 6px;
  margin-right: 5px;
  margin-bottom: 0;
  color: #666;
  font-size: 18px;
  line-height: 0;
  border-color: transparent;
}
.navbar .navbar-nav > li > a {
  font-size: 12px;
}
.navbar .navbar-header .navbar-brand {
  color: #CCC;
  font-weight: 600;
  position: relative;
  top: 0;
}
.navbar .navbar-header .navbar-brand:hover {
  color: #FFF;
}
.navbar .navbar-nav > li > .dropdown-menu {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.navbar .navbar-nav > li > .dropdown-menu::before,
.navbar .navbar-nav > li > .dropdown-menu::after {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #CCC;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 9px;
}
.navbar .navbar-nav > li > .dropdown-menu::after {
  border-left-width: 6px;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-bottom-color: #fff;
  top: -6px;
  left: 10px;
}
.navbar .navbar-nav.navbar-right > li > .dropdown-menu::before,
.navbar .navbar-nav.navbar-right > li > .dropdown-menu::after {
  left: auto;
  right: 9px;
}
.navbar .navbar-nav.navbar-right > li > .dropdown-menu::after {
  left: auto;
  right: 10px;
}
.navbar.navbar-inverse .navbar-form .search-query {
  width: 225px;
  margin-top: 2px;
  background-color: #333;
  font-size: 11px;
  font-weight: bold;
  line-height: 20px;
  border-color: #000;
  border-radius: 20px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.15);
}
.navbar.navbar-inverse .navbar-form .search-query:focus {
  background-color: #FFF;
}
.navbar.navbar-inverse .navbar-form .search-query::-webkit-input-placeholder {
  color: #666;
}
.navbar.navbar-inverse .navbar-form .search-query::-moz-placeholder {
  color: #666;
}
/*------------------------------------------------------------------
[ Subnavbar / .subnavbar ]
*/
.subnavbar {
  margin-bottom: 2.5em;
  border-top: 1px solid #595959;
}
.subnavbar .subnav-toggle {
  display: none;
  padding: .5em 0;
  margin: 0 auto;
  font-size: 18px;
  text-align: center;
  color: #ff9900;
}
.subnavbar .subnav-toggle:hover {
  text-decoration: none;
}
.subnavbar .subnavbar-inner {
  height: 80px;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#414141), to(#323232));
  background-image: -webkit-linear-gradient(to bottom, #414141, 0%, #323232, 100%);
  background-image: -moz-linear-gradient(to bottom, #414141 0%, #323232 100%);
  background-image: linear-gradient(to bottom, #414141 0%, #323232 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff414141', endColorstr='#ff323232', GradientType=0);
  border-bottom: 1px solid #000;
}
.subnavbar .btn-subnavbar {
  display: none;
  padding: 10px 0 8px;
  color: #F90;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}
.subnavbar .btn-subnavbar:hover {
  text-decoration: none;
}
.subnavbar .dropdown .caret {
  margin-top: -16px;
  border-top-color: #888;
  border-bottom-color: #888;
  opacity: 35;
  filter: alpha(opacity=3500);
}
.subnavbar .dropdown.open .caret {
  display: none;
}
.subnavbar .mainnav {
  display: inline-block;
  height: 80px;
  padding: 0;
  margin: 0;
  border-left: 1px solid #292929;
  border-right: 1px solid #4A4A4A;
}
.subnavbar .mainnav > li {
  float: left;
  min-width: 90px;
  height: 80px;
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: none;
  border-left: 1px solid #4A4A4A;
  border-right: 1px solid #292929;
}
.subnavbar .mainnav > li > a {
  display: block;
  height: 100%;
  padding: 0 15px;
  font-size: 12px;
  font-weight: bold;
  color: #636363;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.subnavbar .mainnav > li > a:hover {
  color: #888;
  text-decoration: none;
}
.subnavbar .mainnav > li > a > i {
  position: relative;
  top: 2px;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: 12px;
  margin-bottom: 9px;
  font-size: 28px;
}
.subnavbar .mainnav > li > a > span {
  display: block;
  padding-bottom: 2px;
}
.subnavbar .mainnav > li.open > a {
  text-decoration: none;
}
.subnavbar .mainnav > li.active > a {
  background: #333;
  color: #F90;
}
.subnavbar .mainnav > li.active > a .caret {
  border-top-color: #ff9900;
  border-bottom-color: #ff9900;
}
.subnavbar .dropdown > .dropdown-menu {
  top: 97%;
  text-align: left;
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.subnavbar .dropdown > .dropdown-menu a {
  font-size: 12px;
}
.subnavbar .dropdown > .dropdown-menu::before,
.subnavbar .dropdown > .dropdown-menu::after {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #CCC;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 9px;
}
.subnavbar .dropdown > .dropdown-menu::after {
  border-left-width: 6px;
  border-right-width: 6px;
  border-bottom-width: 6px;
  border-bottom-color: #fff;
  top: -6px;
  left: 10px;
}
.subnavbar .dropdown-submenu .dropdown-menu:after,
.subnavbar .dropdown-submenu .dropdown-menu:before {
  display: none;
}
.subnavbar .subnav-collapse.collapse {
  height: auto\9;
  overflow: visible\9;
}
/*------------------------------------------------------------------
[ Main / .main ]
*/
.main {
  padding-bottom: 2em;
}
/*------------------------------------------------------------------
[ Extra / .extra ]
*/
.extra {
  border-top: 1px solid #000;
  padding: 20px 0;
  font-size: 11px;
  color: #BBB;
  background: #1A1A1A;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3);
}
.extra a {
  color: #666;
}
.extra h4 {
  margin-bottom: 1em;
  font-weight: 400;
}
.extra ul {
  padding: 0;
  margin: 0;
}
.extra ul li {
  margin-bottom: .6em;
  list-style: none;
}
/*------------------------------------------------------------------
[ Footer/ .footer ]
*/
.footer {
  margin-top: 0;
  border-top: 1px solid #000;
  padding: 15px 0;
  font-size: 12px;
  background: #111;
  color: #999;
  -webkit-box-shadow: inset 0 1px 0 #292929;
  box-shadow: inset 0 1px 0 #292929;
}
.footer #footer-terms {
  text-align: right;
}
.footer a {
  color: #FFF;
}
.footer a:hover {
  color: #FFF;
  text-decoration: none;
}
/*------------------------------------------------------------------
[ Error / .error-container ]
*/
.error-container {
  margin-top: 4em;
  margin-bottom: 4em;
  text-align: center;
}
.error-container h1 {
  margin-bottom: .5em;
  font-size: 120px;
  line-height: 1em;
}
.error-container h2 {
  margin-bottom: .75em;
  font-size: 28px;
}
.error-container .error-details {
  margin-bottom: 1.5em;
  font-size: 16px;
}
.error-container .error-actions a {
  margin: 0 .5em;
}
/*------------------------------------------------------------------
[ Datepicker / .ui-datepicker ]
*/
.ui-datepicker {
  padding: .35em .35em 0;
}
.ui-datepicker th {
  font-weight: bold;
  color: gray;
}
.ui-datepicker .ui-widget-header {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#555555), to(#363636));
  background-image: -webkit-linear-gradient(to bottom, #555555, 0%, #363636, 100%);
  background-image: -moz-linear-gradient(to bottom, #555555 0%, #363636 100%);
  background-image: linear-gradient(to bottom, #555555 0%, #363636 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff555555', endColorstr='#ff363636', GradientType=0);
  border: 1px solid #2f2f2f;
  -webkit-box-shadow: inset 0 1px 0 #838383;
  box-shadow: inset 0 1px 0 #838383;
}
.ui-datepicker td a {
  margin-bottom: 0px;
  border: 0px;
}
.ui-datepicker td:hover {
  color: #ffffff;
}
.ui-datepicker td .ui-state-default {
  padding: 6px;
  margin-bottom: 0px;
  color: #505050;
  font-size: 11px;
  text-align: center;
  background: none;
  filter: none;
  border: 0px;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.25);
}
.ui-datepicker td .ui-state-default:hover {
  color: #ffffff;
  background: #999999;
  text-shadow: none;
}
.ui-datepicker td .ui-state-active {
  margin-bottom: 0px;
  color: #ffffff;
  font-size: normal;
  background: #ff9900;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.ui-datepicker .ui-state-hover,
.ui-datepicker .ui-widget-content .ui-state-hover,
.ui-datepicker .ui-widget-header .ui-state-hover,
.ui-datepicker .ui-state-focus,
.ui-datepicker .ui-widget-content .ui-state-focus,
.ui-datepicker .ui-widget-header .ui-state-focus {
  background: #ffffff;
  border: 1px solid #b36b00;
}
.ui-datepicker-today a:hover {
  color: #ffffff;
  background-color: #ff9900;
}
.ui-datepicker-today a {
  padding: 0 1px;
  margin-bottom: 0px;
  background-color: #999999;
  cursor: pointer;
}
.ui-datepicker-next-hover .ui-icon,
.ui-datepicker-prev-hover .ui-icon {
  background-color: #ffffff;
  opacity: .5;
}
/*------------------------------------------------------------------
[ Slider / .ui-slider ]
*/
.ui-slider {
  position: relative;
  text-align: left;
}
.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 25px;
  height: 16px;
  background: url(../img/jquery/handle.png) no-repeat;
  border: none;
  cursor: pointer;
}
.ui-slider .ui-slider-handle:hover {
  background-position: 0 -16px;
}
.ui-slider .ui-slider-handle:active {
  background-position: 0 -16px;
}
.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  height: 6px;
  font-size: .7em;
  display: block;
  border: 1px solid #FFF;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.35);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.35);
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#cccccc), to(#bdbdbd));
  background-image: -webkit-linear-gradient(to bottom, #cccccc, 0%, #bdbdbd, 100%);
  background-image: -moz-linear-gradient(to bottom, #cccccc 0%, #bdbdbd 100%);
  background-image: linear-gradient(to bottom, #cccccc 0%, #bdbdbd 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffcccccc', endColorstr='#ffbdbdbd', GradientType=0);
  border-color: #b3b3b3;
}
.ui-slider.slider-primary .ui-slider-range {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#ffa929), to(#ff9d0a));
  background-image: -webkit-linear-gradient(to bottom, #ffa929, 0%, #ff9d0a, 100%);
  background-image: -moz-linear-gradient(to bottom, #ffa929 0%, #ff9d0a 100%);
  background-image: linear-gradient(to bottom, #ffa929 0%, #ff9d0a 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffa929', endColorstr='#ffff9d0a', GradientType=0);
  border-color: #cc7a00;
}
.ui-slider.slider-secondary .ui-slider-range {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#333333), to(#242424));
  background-image: -webkit-linear-gradient(to bottom, #333333, 0%, #242424, 100%);
  background-image: -moz-linear-gradient(to bottom, #333333 0%, #242424 100%);
  background-image: linear-gradient(to bottom, #333333 0%, #242424 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff333333', endColorstr='#ff242424', GradientType=0);
  border-color: #1a1a1a;
}
.ui-slider.slider-tertiary .ui-slider-range {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#777777), to(#686868));
  background-image: -webkit-linear-gradient(to bottom, #777777, 0%, #686868, 100%);
  background-image: -moz-linear-gradient(to bottom, #777777 0%, #686868 100%);
  background-image: linear-gradient(to bottom, #777777 0%, #686868 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff777777', endColorstr='#ff686868', GradientType=0);
  border-color: #5e5e5e;
}
.ui-slider-horizontal {
  height: 12px;
}
.ui-slider-horizontal .ui-slider-handle {
  top: -4px;
  margin-left: -0.6em;
}
.ui-slider-horizontal .ui-slider-range {
  top: -1px;
  height: 110%;
}
.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}
.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}
.ui-slider-vertical {
  width: 11px;
  height: 100px;
}
.ui-slider-vertical .ui-slider-handle {
  left: -3px;
  margin-left: 0;
  margin-bottom: -0.6em;
  width: 15px;
  height: 24px;
  background: url(../img/jquery/handle-vertical.png) no-repeat;
}
.ui-slider-vertical .ui-slider-handle:hover {
  background-position: 0 -24px;
}
.ui-slider-vertical .ui-slider-handle:active {
  background-position: 0 -24px;
}
.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 9px;
}
.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}
.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}
/*------------------------------------------------------------------
[ Validation / .control-group ]
*/
.form-group {
  position: relative;
}
.form-group span.error {
  display: inline-block !important;
  color: #B94A48;
  font-size: 11px;
  font-weight: 600;
  position: relative;
  top: -5px;
  padding: 4px 8px;
  margin-bottom: 1em;
  color: #FFF;
  background: #B94A48;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.35);
}
.form-group span.error::after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #B94A48;
  position: absolute;
  bottom: -6px;
  left: 7px;
}
.form-group.error {
  padding-bottom: .5em;
}
.form-group.error div.clean {
  display: none;
}
.form-group.success span.error {
  display: none !important;
}
.form-group.success span.error::after {
  border: none;
}
.form-group.success div.clean {
  display: none;
  position: absolute;
  left: 0;
  width: 15px;
  height: 15px;
  background: url(../img/validation/validation-success.png) no-repeat 0 0;
}
.form-horizontal .form-group span.error {
  margin-left: 1.5em;
  margin-left: 15px;
}
.form-group {
  margin-bottom: 30px;
}
/*------------------------------------------------------------------
[ Widget / .widget ]
*/
.widget {
  position: relative;
  clear: both;
  width: auto;
  margin-bottom: 2em;
  /* Clearfix Hack */

  /* Widget Table */

  /* Widget Plain */

  /* Widget Box */

}
.widget .widget-header {
  position: relative;
  height: 40px;
  line-height: 40px;
  background: #E9E9E9;
  background: -moz-linear-gradient(to bottom, #fafafa 0%, #e9e9e9 100%);
  /* FF3.6+ */

  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fafafa), color-stop(100%, #e9e9e9));
  /* Chrome,Safari4+ */

  background: -webkit-linear-gradient(to bottom, #fafafa 0%, #e9e9e9 100%);
  /* Chrome10+,Safari5.1+ */

  background: -o-linear-gradient(to bottom, #fafafa 0%, #e9e9e9 100%);
  /* Opera11.10+ */

  background: -ms-linear-gradient(to bottom, #fafafa 0%, #e9e9e9 100%);
  /* IE10+ */

  background: linear-gradient(to bottom, #fafafa 0%, #e9e9e9 100%);
  /* W3C */

  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FAFAFA', endColorstr='#E9E9E9');
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FAFAFA', endColorstr='#E9E9E9')";
  border: 1px solid #D5D5D5;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-background-clip: padding-box;
}
.widget .widget-header h3 {
  top: 0;
  position: relative;
  left: 10px;
  display: inline-block;
  margin-right: 3em;
  font-size: 15px;
  font-weight: 400;
  color: #555;
  line-height: 18px;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
}
.widget .widget-header > [class^="fa-"],
.widget .widget-header > [class*="fa-"] {
  display: inline-block;
  margin-top: -3px;
  margin-left: 13px;
  margin-right: -2px;
  font-size: 16px;
  color: #555;
  vertical-align: middle;
}
.widget .widget-content {
  padding: 25px 15px 15px;
  background: #FFF;
  border: 1px solid #D5D5D5;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.widget .widget-header + .widget-content {
  border-top: none;
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.widget.widget-nopad .widget-content {
  padding: 0;
}
.widget .widget-content:before,
.widget .widget-content:after {
  content: " ";
  /* 1 */

  display: table;
  /* 2 */

}
.widget .widget-content:after {
  clear: both;
}
.widget .widget-content:before,
.widget .widget-content:after {
  content: " ";
  /* 1 */

  display: table;
  /* 2 */

}
.widget .widget-content:after {
  clear: both;
}
.widget.widget-table .widget-content {
  padding: 0;
}
.widget.widget-table .table {
  margin-bottom: 0;
  border: none;
}
.widget.widget-table .table tr td:first-child,
.widget.widget-table .table tr th:first-child {
  border-left: none;
}
.widget.widget-plain {
  background: transparent;
  border: none;
}
.widget.widget-plain .widget-content {
  padding: 0;
  background: transparent;
  border: none;
}
.widget.widget-box .widget-content {
  background: #E3E3E3;
  background: #FFF;
}
/*------------------------------------------------------------------
[ Shortcuts / .shortcuts ]
*/
.shortcuts {
  text-align: center;
}
.shortcuts .shortcut {
  width: 110px;
  display: inline-block;
  padding: 15px 0;
  margin: 0 5px 1em;
  vertical-align: top;
  text-decoration: none;
  background: #F3F3F3;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#ffffff), to(#eeeeee));
  background-image: -webkit-linear-gradient(to bottom, #ffffff, 0%, #eeeeee, 100%);
  background-image: -moz-linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffeeeeee', GradientType=0);
  border: 1px solid #ddd;
  box-sizing: border-box;
  border-radius: 5px;
}
.shortcuts .shortcut .shortcut-icon {
  width: 100%;
  margin-top: .25em;
  margin-bottom: .35em;
  font-size: 32px;
  color: #555;
}
.shortcuts .shortcut:hover {
  background: #E8E8E8;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#fafafa), to(#e1e1e1));
  background-image: -webkit-linear-gradient(to bottom, #fafafa, 0%, #e1e1e1, 100%);
  background-image: -moz-linear-gradient(to bottom, #fafafa 0%, #e1e1e1 100%);
  background-image: linear-gradient(to bottom, #fafafa 0%, #e1e1e1 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffafafa', endColorstr='#ffe1e1e1', GradientType=0);
}
.shortcuts .shortcut:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.shortcuts .shortcut:hover .shortcut-icon {
  color: #666;
}
.shortcuts .shortcut-label {
  display: block;
  margin-top: .75em;
  font-weight: 400;
  color: #666;
}
/*------------------------------------------------------------------
[ Item Row / .item-row ]
*/
.item-row {
  display: table;
  width: 100%;
  padding-bottom: 3.5em;
  margin-bottom: 3.5em;
  border-bottom: 1px dotted #BBB;
}
.item-row .item-label {
  display: table-cell;
  vertical-align: top;
  width: 200px;
  font-size: 13px;
  font-weight: 600;
}
.item-row .item-content {
  display: table-cell;
}
/*------------------------------------------------------------------
[ MsgBox / .jquery-msgbox ]
*/
.jquery-msgbox-wrapper {
  padding-left: 90px;
}
.jquery-msgbox-alert {
  background: url(../img/notifications/alert-48.png) no-repeat 21px 20px;
}
.jquery-msgbox-info {
  background: url(../img/notifications/info-48.png) no-repeat 21px 20px;
}
.jquery-msgbox-error {
  background: url(../img/notifications/error-48.png) no-repeat 21px 20px;
}
.jquery-msgbox-prompt {
  background: url(../img/notifications/question-dark-48.png) no-repeat 20px 20px;
}
.jquery-msgbox-confirm {
  background: url(../img/notifications/question-48.png) no-repeat 20px 20px;
}
/*------------------------------------------------------------------
[ Growl / .msgGrowl ]
*/
.msgGrowl.success .msgGrowl-content {
  background: url(../img/notifications/success-32.png) no-repeat 7px 13px;
}
.msgGrowl.error .msgGrowl-content {
  background: url(../img/notifications/error-32.png) no-repeat 7px 13px;
}
.msgGrowl.info .msgGrowl-content {
  background: url(../img/notifications/info-32.png) no-repeat 7px 13px;
}
.msgGrowl.warning .msgGrowl-content {
  background: url(../img/notifications/alert-32.png) no-repeat 7px 13px;
}
/*------------------------------------------------------------------
[ Back to Top / #back-to-top ]
*/
#back-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
  width: 45px;
  height: 45px;
  display: block;
  background: #222;
  background: rgba(0, 0, 0, 0.75);
  font-size: 16px;
  text-align: center;
  line-height: 45px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  cursor: pointer;
}
#back-to-top:hover {
  text-decoration: none;
  background-color: #F90;
  background: rgba(255, 153, 0, 0.75);
}
#back-to-top i {
  color: #fff;
}
/*------------------------------------------------------------------
[ Stacked / .stacked ]
*/
.stacked {
  position: relative;
}
.stacked:after,
.stacked:before {
  content: '';
  position: absolute;
  display: block;
  height: 1px;
  bottom: -2px;
  left: 3px;
  right: 3px;
  background-color: #ffffff;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
  border: 1px solid #d3d3d3;
  border-top: 0;
}
.stacked:before {
  bottom: -4px;
  left: 6px;
  right: 6px;
}
.nav-tabs > li > a,
.nav-pills > li > a {
  padding: 7px 12px;
}
.tab-content {
  margin-top: 1.75em;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  border-color: #eee;
}
.nav-pills > li > a,
.nav-pills > li > a:hover {
  color: #555;
}
.nav-pills > li > a .caret,
.nav-pills > li > a:hover .caret {
  margin-top: -2px;
  border-top-color: #555;
  border-bottom-color: #555;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background-color: #ff9900;
}
.nav-tabs > li > a,
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  color: #555;
  border-top-width: 3px;
}
.nav-tabs > li > a .caret,
.nav-tabs > li > a:hover .caret,
.nav-tabs > li > a:focus .caret {
  margin-top: -2px;
  border-top-color: #555;
  border-bottom-color: #555;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border-top: 3px solid #ff9900;
}
a.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
  color: #555;
}
/*------------------------------------------------------------------
[ Tables / .table ]
*/
.table-bordered thead tr th {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 13px;
  font-weight: 600;
  color: #444;
  border-left: 1px solid #F1F1F1;
  border-right: 1px solid #CCC;
  -webkit-box-shadow: inset 0 1px 0 #ffffff;
  box-shadow: inset 0 1px 0 #ffffff;
}
.table-bordered thead tr th:first-child {
  border-left-color: #CCC;
}
.table-bordered thead tr th:last-child {
  border-right: none;
}
.table-bordered tbody tr:first-child td {
  border-top-color: #CCC;
}
.table-bordered tbody tr td {
  border-left: 1px solid #FFF;
  border-right: 1px solid #DDD;
}
.table-bordered tbody tr td:first-child {
  border-left-color: #DDD;
}
.table-bordered tbody tr td:last-child {
  border-right: none;
}
.table-bordered {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.table-bordered thead tr {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#f5f5f5), to(#e2e2e2));
  background-image: -webkit-linear-gradient(to bottom, #f5f5f5, 0%, #e2e2e2, 100%);
  background-image: -moz-linear-gradient(to bottom, #f5f5f5 0%, #e2e2e2 100%);
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #e2e2e2 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe2e2e2', GradientType=0);
}
.table-bordered thead th {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  font-weight: 400;
  color: #444;
  border-left: 1px solid #F1F1F1;
  border-right: 1px solid #CCC;
  -moz-box-shadow: inset 0 1px 0 #ffffff;
  -webkit-box-shadow: inset 0 1px 0 #ffffff;
  box-shadow: inset 0 1px 0 #ffffff;
}
.table-bordered thead th:first-child {
  border-left-color: #CCC;
}
.table-bordered thead th:last-child {
  border-right: none;
}
.table-bordered tbody td {
  border-left: 1px solid #FFF;
  border-right: 1px solid #DDD;
}
.table-bordered tbody tr td:first-child {
  border-left-color: #DDD;
}
.table-bordered tbody tr td:last-child {
  border-right: none;
}
/*------------------------------------------------------------------
[ Accordion / .accordion-group ]
*/
.accordion.highlight .panel.open.panel-default > .panel-heading {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#ff9900), to(#cc7a00));
  background-image: -webkit-linear-gradient(to bottom, #ff9900, 0%, #cc7a00, 100%);
  background-image: -moz-linear-gradient(to bottom, #ff9900 0%, #cc7a00 100%);
  background-image: linear-gradient(to bottom, #ff9900 0%, #cc7a00 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff9900', endColorstr='#ffcc7a00', GradientType=0);
  border-color: #b36b00;
}
.accordion .panel.open.panel-default > .panel-heading {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#555555), to(#3b3b3b));
  background-image: -webkit-linear-gradient(to bottom, #555555, 0%, #3b3b3b, 100%);
  background-image: -moz-linear-gradient(to bottom, #555555 0%, #3b3b3b 100%);
  background-image: linear-gradient(to bottom, #555555 0%, #3b3b3b 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff555555', endColorstr='#ff3b3b3b', GradientType=0);
  border: 1px solid #222222;
  color: #FFF;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.35);
}
.panel .accordion-toggle {
  display: block;
  font-size: 15px;
  font-weight: 400;
}
.panel .accordion-toggle:hover {
  text-decoration: none;
}
/*------------------------------------------------------------------
[ Modal / .modal ]
*/
.modal {
  border: none;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
}
.modal-header {
  padding: 17px 20px;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#444444), to(#2d2d2d));
  background-image: -webkit-linear-gradient(to bottom, #444444, 0%, #2d2d2d, 100%);
  background-image: -moz-linear-gradient(to bottom, #444444 0%, #2d2d2d 100%);
  background-image: linear-gradient(to bottom, #444444 0%, #2d2d2d 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff444444', endColorstr='#ff2d2d2d', GradientType=0);
  border: 1px solid #111111;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.modal-header h1,
.modal-header h2,
.modal-header h3,
.modal-header h4,
.modal-header h5,
.modal-header h6 {
  margin-bottom: 0;
  color: #ffffff;
  font-weight: 400;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
/*------------------------------------------------------------------
[ Dropdown Menu / .dropdown-menu ]
*/
.dropdown-menu {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.dropdown-menu > li > a {
  padding: 5px 9px;
  margin: 0 6px;
  font-size: 12px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #FFF;
  background-color: #ff9900;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #ffffff;
  background: #ff9900;
  text-shadow: none;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -5px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
/*------------------------------------------------------------------
[ Pagination / .pagination ]
*/
ul.pagination li a {
  padding: 0 10px;
  margin-right: .25em;
  margin-left: .25em;
  color: #444;
  line-height: 32px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
ul.pagination li.active a {
  font-weight: 600;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.45);
  background-color: #F90;
  border-color: #F90;
}
ul.pagination li.active a:hover {
  background-color: #F90;
  border-color: #F90;
}
.pager li {
  margin-right: .25em;
  margin-left: .25em;
}
.pager li > a {
  color: #444;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}
/*------------------------------------------------------------------
[ Gallery / .gallery-container ]
*/
.gallery-container {
  margin: 0;
  margin-bottom: 20px;
  list-style: none;
  text-align: center;
}
.gallery-container li {
  width: 200px;
  display: inline-block;
  vertical-align: top;
  margin: 0 5px;
  margin-bottom: 2em;
  border: 1px solid #d3d3d3;
  position: relative;
  padding: 6px;
}
.gallery-container img {
  position: relative;
  z-index: 10;
  max-width: 100%;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  cursor: pointer;
}
.gallery-container li {
  position: relative;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.gallery-container li :after,
.gallery-container li :before {
  content: '';
  position: absolute;
  display: block;
  height: 1px;
  bottom: -3px;
  left: 3px;
  right: 3px;
  background-color: #ffffff;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
  border: 1px solid #d3d3d3;
  border-top: 0;
}
.gallery-container li :before {
  bottom: -5px;
  left: 6px;
  right: 6px;
}
.preview {
  position: absolute;
  top: 7px;
  left: 7px;
  z-index: 20;
  display: none;
  width: 184px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.65);
  background-image: url(../img/gallery/zoom.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.btn-default,
.btn-primary,
.btn-secondary,
.btn-tertiary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-default:active,
.btn-primary:active,
.btn-secondary:active,
.btn-tertiary:active,
.btn-success:active,
.btn-info:active,
.btn-warning:active,
.btn-danger:active,
.btn-default.active,
.btn-primary.active,
.btn-secondary.active,
.btn-tertiary.active,
.btn-success.active,
.btn-info.active,
.btn-warning.active,
.btn-danger.active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:active,
.btn.active {
  background-image: none;
}
.btn-default {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(to bottom, #ffffff, 0%, #e6e6e6, 100%);
  background-image: -moz-linear-gradient(to bottom, #ffffff 0%, #e6e6e6 100%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #e6e6e6 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
  border-color: #e0e0e0;
  text-shadow: 0 1px 0 #fff;
  border-color: #ccc;
}
.btn-default:active,
.btn-default.active {
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}
.btn-primary {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#ff9900), to(#cc7a00));
  background-image: -webkit-linear-gradient(to bottom, #ff9900, 0%, #cc7a00, 100%);
  background-image: -moz-linear-gradient(to bottom, #ff9900 0%, #cc7a00 100%);
  background-image: linear-gradient(to bottom, #ff9900 0%, #cc7a00 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff9900', endColorstr='#ffcc7a00', GradientType=0);
  border-color: #c27400;
}
.btn-primary:active,
.btn-primary.active {
  background-color: #cc7a00;
  border-color: #c27400;
}
.btn-primary:hover {
  border-color: #c27400;
}
.btn-success {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#5cb85c), to(#449d44));
  background-image: -webkit-linear-gradient(to bottom, #5cb85c, 0%, #449d44, 100%);
  background-image: -moz-linear-gradient(to bottom, #5cb85c 0%, #449d44 100%);
  background-image: linear-gradient(to bottom, #5cb85c 0%, #449d44 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff449d44', GradientType=0);
  border-color: #419641;
}
.btn-success:active,
.btn-success.active {
  background-color: #449d44;
  border-color: #419641;
}
.btn-warning {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#ffbb1c), to(#e8a300));
  background-image: -webkit-linear-gradient(to bottom, #ffbb1c, 0%, #e8a300, 100%);
  background-image: -moz-linear-gradient(to bottom, #ffbb1c 0%, #e8a300 100%);
  background-image: linear-gradient(to bottom, #ffbb1c 0%, #e8a300 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffbb1c', endColorstr='#ffe8a300', GradientType=0);
  border-color: #de9b00;
}
.btn-warning:active,
.btn-warning.active {
  background-color: #e8a300;
  border-color: #de9b00;
}
.btn-danger {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#d9534f), to(#c9302c));
  background-image: -webkit-linear-gradient(to bottom, #d9534f, 0%, #c9302c, 100%);
  background-image: -moz-linear-gradient(to bottom, #d9534f 0%, #c9302c 100%);
  background-image: linear-gradient(to bottom, #d9534f 0%, #c9302c 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffc9302c', GradientType=0);
  border-color: #c12e2a;
}
.btn-danger:active,
.btn-danger.active {
  background-color: #c9302c;
  border-color: #c12e2a;
}
.btn-info {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#5bc0de), to(#31b0d5));
  background-image: -webkit-linear-gradient(to bottom, #5bc0de, 0%, #31b0d5, 100%);
  background-image: -moz-linear-gradient(to bottom, #5bc0de 0%, #31b0d5 100%);
  background-image: linear-gradient(to bottom, #5bc0de 0%, #31b0d5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff31b0d5', GradientType=0);
  border-color: #2aabd2;
}
.btn-info:active,
.btn-info.active {
  background-color: #31b0d5;
  border-color: #2aabd2;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-color: #cc7a00;
  border-color: #c27400;
}
.thumbnail,
.img-thumbnail {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}
.navbar {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#ffffff), to(#f8f8f8));
  background-image: -webkit-linear-gradient(to bottom, #ffffff, 0%, #f8f8f8, 100%);
  background-image: -moz-linear-gradient(to bottom, #ffffff 0%, #f8f8f8 100%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #f8f8f8 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff8f8f8', GradientType=0);
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.075);
}
.navbar .navbar-nav > .active > a {
  background-color: #f8f8f8;
}
.navbar-brand,
.navbar-nav > li > a {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
}
.navbar-inverse {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#3c3c3c), to(#222222));
  background-image: -webkit-linear-gradient(to bottom, #3c3c3c, 0%, #222222, 100%);
  background-image: -moz-linear-gradient(to bottom, #3c3c3c 0%, #222222 100%);
  background-image: linear-gradient(to bottom, #3c3c3c 0%, #222222 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3c3c3c', endColorstr='#ff222222', GradientType=0);
}
.navbar-inverse .navbar-nav > .active > a {
  background-color: #222222;
}
.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav > li > a {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}
.progress {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#ebebeb), to(#f5f5f5));
  background-image: -webkit-linear-gradient(to bottom, #ebebeb, 0%, #f5f5f5, 100%);
  background-image: -moz-linear-gradient(to bottom, #ebebeb 0%, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #ebebeb 0%, #f5f5f5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffebebeb', endColorstr='#fff5f5f5', GradientType=0);
}
.progress-bar {
  background: #428bca;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#428bca), to(#3071a9));
  background-image: -webkit-linear-gradient(to bottom, #428bca, 0%, #3071a9, 100%);
  background-image: -moz-linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
  background-image: linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff428bca', endColorstr='#ff3071a9', GradientType=0);
}
.progress-bar-success {
  background: #5cb85c;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#5cb85c), to(#449d44));
  background-image: -webkit-linear-gradient(to bottom, #5cb85c, 0%, #449d44, 100%);
  background-image: -moz-linear-gradient(to bottom, #5cb85c 0%, #449d44 100%);
  background-image: linear-gradient(to bottom, #5cb85c 0%, #449d44 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff449d44', GradientType=0);
}
.progress-bar-info {
  background: #5bc0de;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#5bc0de), to(#31b0d5));
  background-image: -webkit-linear-gradient(to bottom, #5bc0de, 0%, #31b0d5, 100%);
  background-image: -moz-linear-gradient(to bottom, #5bc0de 0%, #31b0d5 100%);
  background-image: linear-gradient(to bottom, #5bc0de 0%, #31b0d5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff31b0d5', GradientType=0);
}
.progress-bar-warning {
  background: #ffbb1c;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#ffbb1c), to(#e8a300));
  background-image: -webkit-linear-gradient(to bottom, #ffbb1c, 0%, #e8a300, 100%);
  background-image: -moz-linear-gradient(to bottom, #ffbb1c 0%, #e8a300 100%);
  background-image: linear-gradient(to bottom, #ffbb1c 0%, #e8a300 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffbb1c', endColorstr='#ffe8a300', GradientType=0);
}
.progress-bar-danger {
  background: #d9534f;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#d9534f), to(#c9302c));
  background-image: -webkit-linear-gradient(to bottom, #d9534f, 0%, #c9302c, 100%);
  background-image: -moz-linear-gradient(to bottom, #d9534f 0%, #c9302c 100%);
  background-image: linear-gradient(to bottom, #d9534f 0%, #c9302c 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffc9302c', GradientType=0);
}
.progress-bar-primary {
  background: #ff9900;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#ff9900), to(#cc7a00));
  background-image: -webkit-linear-gradient(to bottom, #ff9900, 0%, #cc7a00, 100%);
  background-image: -moz-linear-gradient(to bottom, #ff9900 0%, #cc7a00 100%);
  background-image: linear-gradient(to bottom, #ff9900 0%, #cc7a00 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff9900', endColorstr='#ffcc7a00', GradientType=0);
}
.progress-bar-secondary {
  background: #666666;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#666666), to(#4d4d4d));
  background-image: -webkit-linear-gradient(to bottom, #666666, 0%, #4d4d4d, 100%);
  background-image: -moz-linear-gradient(to bottom, #666666 0%, #4d4d4d 100%);
  background-image: linear-gradient(to bottom, #666666 0%, #4d4d4d 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff666666', endColorstr='#ff4d4d4d', GradientType=0);
}
.progress-bar-tertiary {
  background: #aaaaaa;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#aaaaaa), to(#919191));
  background-image: -webkit-linear-gradient(to bottom, #aaaaaa, 0%, #919191, 100%);
  background-image: -moz-linear-gradient(to bottom, #aaaaaa 0%, #919191 100%);
  background-image: linear-gradient(to bottom, #aaaaaa 0%, #919191 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffaaaaaa', endColorstr='#ff919191', GradientType=0);
}
.list-group {
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 #cc7a00;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#ff9900), to(#d98200));
  background-image: -webkit-linear-gradient(to bottom, #ff9900, 0%, #d98200, 100%);
  background-image: -moz-linear-gradient(to bottom, #ff9900 0%, #d98200 100%);
  background-image: linear-gradient(to bottom, #ff9900 0%, #d98200 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff9900', endColorstr='#ffd98200', GradientType=0);
  border-color: #d98200;
}
.panel {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.panel-primary > .panel-heading {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#428bca), to(#357ebd));
  background-image: -webkit-linear-gradient(to bottom, #428bca, 0%, #357ebd, 100%);
  background-image: -moz-linear-gradient(to bottom, #428bca 0%, #357ebd 100%);
  background-image: linear-gradient(to bottom, #428bca 0%, #357ebd 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff428bca', endColorstr='#ff357ebd', GradientType=0);
}
.panel-success > .panel-heading {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#dff0d8), to(#d0e9c6));
  background-image: -webkit-linear-gradient(to bottom, #dff0d8, 0%, #d0e9c6, 100%);
  background-image: -moz-linear-gradient(to bottom, #dff0d8 0%, #d0e9c6 100%);
  background-image: linear-gradient(to bottom, #dff0d8 0%, #d0e9c6 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdff0d8', endColorstr='#ffd0e9c6', GradientType=0);
}
.panel-info > .panel-heading {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#d9edf7), to(#c4e3f3));
  background-image: -webkit-linear-gradient(to bottom, #d9edf7, 0%, #c4e3f3, 100%);
  background-image: -moz-linear-gradient(to bottom, #d9edf7 0%, #c4e3f3 100%);
  background-image: linear-gradient(to bottom, #d9edf7 0%, #c4e3f3 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffc4e3f3', GradientType=0);
}
.panel-warning > .panel-heading {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#fcf8e3), to(#faf2cc));
  background-image: -webkit-linear-gradient(to bottom, #fcf8e3, 0%, #faf2cc, 100%);
  background-image: -moz-linear-gradient(to bottom, #fcf8e3 0%, #faf2cc 100%);
  background-image: linear-gradient(to bottom, #fcf8e3 0%, #faf2cc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffcf8e3', endColorstr='#fffaf2cc', GradientType=0);
}
.panel-danger > .panel-heading {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#f2dede), to(#ebcccc));
  background-image: -webkit-linear-gradient(to bottom, #f2dede, 0%, #ebcccc, 100%);
  background-image: -moz-linear-gradient(to bottom, #f2dede 0%, #ebcccc 100%);
  background-image: linear-gradient(to bottom, #f2dede 0%, #ebcccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffebcccc', GradientType=0);
}
.panel-default > .panel-heading {
  background-color: #F3F3F3;
}
