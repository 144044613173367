

#big_stats
{
	width: 100%;
	display: table;
	margin-top: .5em;
	margin-bottom: 1em;

}

.big-stats-container .widget-content {
	background: #E9E9E9;
	background:-moz-linear-gradient(to bottom, #FAFAFA 0%, #E9E9E9 100%); /* FF3.6+ */
	background:-webkit-gradient(linear, left to bottom, left bottom, color-stop(0%,#FAFAFA), color-stop(100%,#E9E9E9)); /* Chrome,Safari4+ */
	background:-webkit-linear-gradient(to bottom, #FAFAFA 0%,#E9E9E9 100%); /* Chrome10+,Safari5.1+ */
	background:-o-linear-gradient(to bottom, #FAFAFA 0%,#E9E9E9 100%); /* Opera11.10+ */
	background:-ms-linear-gradient(to bottom, #FAFAFA 0%,#E9E9E9 100%); /* IE10+ */
	background:linear-gradient(to bottom, #FAFAFA 0%,#E9E9E9 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FAFAFA', endColorstr='#E9E9E9');
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FAFAFA', endColorstr='#E9E9E9')";
}

#big_stats .stat
{
	width: 25%;
	height: 90px;
	text-align: center;
	display: table-cell;
	padding: 0;
	position: relative;

	border-right: 1px solid #CCC;
	border-left: 1px solid #FFF;
}

#big_stats .stat:first-child {
	border-left: none;
}

#big_stats .stat:last-child {
	border-right: none;
}

#big_stats .stat h4
{
	font-size: 11px;
	font-weight: bold;
	color: #777;
	margin-bottom: 1.5em;
}

#big_stats .stat .value
{
	font-size: 56px;
	font-weight: bold;
	color: #333;
	line-height: 1em;
}



@media all and (max-width: 950px) and (min-width: 1px) {

	#big_stats {
		display: block;
		margin-bottom: -40px;
	}

	#big_stats .stat {
		width: 49%;
		display: block;
		margin-bottom: 3em;
		float: left;
	}

	#big_stats .stat:nth-child(2) {
		border-right: none;
	}

	#big_stats .stat:nth-child(3) {
		border-left: none;
	}

}

@media (max-width: 767px) {
	#big_stats .stat .value {
		font-size: 40px;
	}
}
