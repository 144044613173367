.full-screen-help-modal .modal-dialog {
  width:100%;
  height:100%;
 	margin: 0;
}

.modal-backdrop.full-screen-help-backdrop, .modal-backdrop.full-screen-help-backdrop.in {
  opacity: 0.85;
  filter: alpha(opacity=85);
}

.full-screen-help-modal .modal-dialog .modal-content {
	background: transparent;
	border: 0;
	color: white;
  font-size: 14px;
  box-shadow: none;
}

.full-screen-help-modal .box {
	padding: 15px;
	border-radius: 7px;
	border: 1px solid #AAA;
	box-shadow: 0 0 25px #000;
	background: #EEE;
	color: #333;
}

.full-screen-help-modal hr {
	margin: 10px 0;
}


.full-screen-help-modal .box .tabbable {
	margin-left: -18px;
	margin-right: -18px;
	border-radius: 5px;
}


.full-screen-help-modal .box .nav-tabs {
	margin-top:15px;
	margin-bottom:0;
	cursor:pointer;
}

.full-screen-help-modal .box .tab-content {
	background-color:white;
	margin: 0px -5px;
	padding-top:20px;
	padding: 15px 20px 15px 15px;
}

/* Glossary and dl's */
.full-screen-help-modal .box dl {
	margin-bottom: 10px;
}

.full-screen-help-modal h1, .full-screen-help-modal h2,
.full-screen-help-modal h3, .full-screen-help-modal h4,
.full-screen-help-modal h5, .full-screen-help-modal h6 {
	/*text-align: left;*/
  font-weight:normal;
	/*margin-bottom: 0;*/
}

.full-screen-help-modal .box h1, .full-screen-help-modal .box h2,
.full-screen-help-modal .box h3, .full-screen-help-modal .box h4,
.full-screen-help-modal .box h5, .full-screen-help-modal .box h6 {
  font-weight:bold;
}


.full-screen-help-modal h4 {
  line-height: 24px;
}

.full-screen-help-modal .box .tab-heading-glossary {
	color: #228B22;
}

.help-button.active {
	color: #F90!important;
}


.remove-pseudo:after,
.remove-pseudo:before {
	display:none!important;
}

.ng-help-youtube-modal .modal-dialog {
  	margin-top: 10%;
	width: 600px;
	left: 50%;
  	margin-left: -300px;
    background-color:#333;
}

.ng-help-youtube-modal .modal-dialog .modal-content {
  background-color:transparent;
  box-shadow: 0 0 50px 20px #DDD;
  border: none;
  margin: 0;
  border-radius: 10px;
}

.ng-help-youtube-modal .modal-dialog .modal-body {
	border-radius: 10px;
	padding: 0;
}


@-webkit-keyframes pulse {
  0% {
  	box-shadow: 0 0 8px #FB2;
  }

  50% {
  	box-shadow: 0 0 24px #F90;
  }

  100% {
  	box-shadow: 0 0 8px #FB2;
  }
}

@keyframes pulse {
  0% {
  	box-shadow: 0 0 8px #FB2;
  }

  50% {
  	box-shadow: 0 0 24px #F90;
  }

  100% {
  	box-shadow: 0 0 8px #FB2;
  }
}


.animated {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.pulse {
  -webkit-animation-name: pulse;
          animation-name: pulse;
}

.help-circle {
    border: 3px solid #F90;
    background-color:white;
    width:35px;
    height:35px;
    margin:0;
    padding:0;
    border-radius:50%;
    text-align:center;
    font-size:150%;
    font-weight:bold;
    color:#333;
    padding-top:5px;
}

.help-circle.muted {
  background-color: #DaDaDa;
  border-color: #999;
}

.box .video-row h5 {
    font-weight: bold;
    margin-bottom:5px;
}

.box .video-row h5 small {
  font-size:100%;
}
.box .video-row p {
  font-size:13px;
  line-height:18px;
}

.box .video-row .col-xs-2 {
  text-align:right;
}
.box .video-row .col-xs-2 a {
  /*this is a janky way to make the video button bigger*/
  font-size:125%;
}


.full-screen-help-modal .right-connector {
  text-align:right;
  border-right:1px solid gray;
  padding:0 5px 0 5px;
}

.full-screen-help-modal .left-connector {
  text-align:left;
  border-left:1px solid gray;
  padding:0 5px 0 5px;
}

.full-screen-help-modal .help-preview {
  background:rgba(100,100,100,0.60);
  width:300px;
  padding:10px;
  border-radius:10px;
}
