.fixed-height-fields .controls > .form-control {
  margin-left:-14px;
  margin-top: 1px;
  border:none;
  box-shadow:none;
  background-color:inherit;
}

.fixed-height-fields .controls > .form-control.input-sm {
  margin-top: 4px;
}
.fixed-height-fields .controls  .control.input-sm {
  margin-top: 3px;
}

.fixed-height-fields .controls  .control.input-sm.select {
  margin-top: 3px;
}


.fixed-height-fields .controls > .form-control.select {
  margin-left:-16px;
}

.fixed-height-fields .form-group.two-lines {
  height:48px;
  margin-top:-1px;
}

.fixed-height-fields .form-group {
  height: 28px;
}

.fixed-height-fields .form-group .control-label.multiline {
  margin-top:-2px;
}


.fixed-height-fields .select2-text {
  height: 50px;
  margin: 3px 0 0 -6px;
}


.fixed-height-fields .row.clearfix {
  border-bottom:1px solid #CCC;
  margin-bottom: 8px;
}

.fixed-height-fields .control  {
  width:240px;
}

.fixed-height-fields .control.narrow  {
  width:60px;
}

.fixed-height-fields .control.small  {
  width:130px;
}

.fixed-height-fields .control.medium  {
  width:180px;
}



.fixed-height-fields .control.fat  {
  width:300px;
}

.fixed-height-fields .form-append {
  margin-left: 20px;
}

.fixed-height-fields h4.heading {
  font-size: 16px;

}

.table .editable-wrap {
  width: 100%;
}
.form-horizontal .table .editable-wrap .form-group {
  margin: auto 0;
}
.form-horizontal .table span {
  margin-top: 2px;
  height:34px;
}
.form-horizontal .table span.form-control {
  margin-top: 2px;
  padding: 6px 11px;
  border:none;
  box-shadow:none;
  background-color:inherit;
}

.editable-empty, .editable-empty:hover, .editable-empty:focus, a.editable-empty, a.editable-empty:hover, a.editable-empty:focus {
 font-style: inherit;
 color: inherit;
 text-decoration: inherit;
}

.panel-heading .btn.right {
  position:absolute;
  right: 20px;
  top: 4px;
}

.editable-checklist label {
  display: block;
  font-weight: normal;
}
