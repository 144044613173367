.alert-beta {
  background-color: #fff;
  border-color: #C4C4C4;
  color: #333;
  padding: 24px;
}

.alert-beta small {
  color:#333;
  font-size:10px;
  line-height:1;
}

.alert-beta h2 {
  font-size: 24px;
}

.alert-beta p {
  margin-bottom: 16px;
}

.alert-trial-banner {
  display: flex;
  align-items: center;
  justify-content: center;
}
