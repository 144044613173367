.upload-form .control-label{
    width:80px;
}

.upload-form .controls {
    margin-left: 90px;
    /*width: 240px;*/
}


.files tr.upload-success td {
    background-color: #DFC!important;
}

td.quick-max-width {
    max-width:250px;
}


.file-drop-over {
    background-color: #DFD;
}

.meteo-legend {
    margin-bottom: 0;
    padding: 0px;
}

.meteo-legend li {
    list-style-type: none;
    display: inline-block;
    list-style-type: none;
    padding-top: 6px;
    width: 145px;
}


.meteo-legend .polygon .fa-stack {
    margin-left:-4px;
    font-size: 98%;
}

.meteo-legend .polygon .fa-stack .fa-square {
    font-size:160%;
    opacity:0.4;
}


.meteo-legend img {
    height:20px;
}

.meteo-button {
    height:80px;
    padding-bottom:10px;
}


.meteo-button .btn {
    font-size:22px;
    height:70px;
    line-height:50px
}
