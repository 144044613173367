.instruction {
  color:#F90;
  font-weight:bold;
}
.nav.navbar-nav.designer-navbar{
  padding-left:30px;
}

.nav.navbar-nav.designer-navbar > li {
  border-left: 1px solid #4A4A4A;
  display: list-item;
}

.nav.navbar-nav.designer-navbar > li:last-child {
  border-right: 1px solid #4A4A4A;
  display: list-item;
}


.nav.navbar-nav.designer-navbar > li > a{
  font-size: 110%;
  font-weight: bold;
  color: #AAA;
}

.nav.navbar-nav.designer-navbar > li > a:hover{
  color: #EEE;
}

.make-scrollable {
  overflow:auto;
}

.map {width:100%;height:100%;}

.designer-content{
  position:absolute;
  top:54px;
  bottom:0;
  left:0;
  right:0;
}

.map-overlay {
  background-color: transparent;
  opacity: 0.92;

  margin: 3px 5px;
  position: relative;
  left: 5px;
  top: 0;
  bottom: 5px;
  width: 300px;
  height: 95%;
  pointer-events:none;
}

/*
  map-navigation-* classes control the zoom-in, zoom-out, and recenter buttons
 */
.map-navigation-container {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  flex-direction: column;
  width: 28px;
}

.map-navigation-button {
  height: 28px;
  margin-bottom: 2px;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  text-align: center;
}

/*
  map-toolbox-* classes control the Map/SLD buttons and the imagery provider dropdown
 */
.map-toolbox-container {
  position: absolute;
  margin: 10px;
  top: 0;
  box-shadow:  0 2px 6px rgba(0,0,0,.3);
  border-radius: 2px;
  display: flex;
  height: 38px;
  align-items: stretch;
  background-color: white;
}

.map-toolbox-button-separator {
  position: relative;
  display: inline-block;
  width: 1px;
  margin: 0 0;
}

.map-toolbox-button {
  border-radius: 2px;
  min-width: 50px;
  border: none;
  background-image: none;
  font-family: Roboto, Arial, sans-serif;
  font-size: 11px;
  font-weight: 500;
}

.map-toolbox-button.active {
  background-color: #ccc;
}

.map-toolbox-dropdown-container {
  position: absolute;
  direction: ltr;
  overflow: hidden;
  display: inline-block;
  top: 10px;
}

.map-toolbox-dropdown-menu {
  direction: ltr;
  overflow: hidden;
  text-align: left;
  position: relative;
  border: none;
  border-radius: 2px;
  padding: 0px;
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
  background-clip: padding-box;
  display: inline-block;
}

.map-toolbox-dropdown-button {
    text-align: left;
    position: relative;
    height: 38px;
    color: rgb(0, 0, 0);
    font-family: Roboto, Arial, sans-serif;
    font-size: 11px;
    padding: 8px;
    border: none;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
    font-weight: 500;
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
  }

  .map-toolbox-dropdown-button.active {
    background-color: rgb(169, 169, 169);
  }

.map-toolbox-dropdown-item {
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
    height: 38px;
    color: #000;
    font-family: Roboto, Arial, sans-serif;
    font-size: 11px;
    padding: 0 12px;
    border: none;
    font-weight: 500;
    background-color: #FFF;
    transition: background-color 0.3s, color 0.3s;
}

.map-toolbox-dropdown-item > button {
    width: 100%;
    border: none;
    background-color: transparent;
    padding: 0;
}

.map-toolbox-dropdown-item:hover {
    background-color: #ebebeb;
}

.disabled-button {
    color: grey;
    background-color: #f0f0f0;
}

.map-overlay .context-container .row {
  margin-left:-5px;
  margin-right:-5px;
}

.map-overlay .context-container {
    position: absolute;
    top: 138px;
    bottom: 0;
    left: 0;
    right: 0;
}


.map-overlay .well {
  max-height:95%;
  overflow-y:visible;
  overflow-x:auto;
  padding:5px 8px;
  margin:0;
  pointer-events:auto;
}

.map-overlay .well.shortcuts {
  overflow:visible;
}

.map-overlay .well input[type="range"] {
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
}

/* This class controls the highlight color of hovered items in the designer menu */
.map-overlay .hover-active {
  background-color:#F90;
}

.map-overlay .toggle-btn.active {
  background-color:#31B0D5;
  text-shadow:none;
  color:#FFF;
  border:1px solid #7BC;
}


.map-overlay .summary  {
  margin-bottom:2px;
}

.map-overlay .shortcut {
  line-height:1.1em;
  font-size:82%;
  width:20%;
  padding: 1px 4px;
  margin: 0 3px;
  height:47px;
  vertical-align:middle;
  border:none;
  background: #f3f3f3;
  cursor: pointer;
}

.map-overlay .shortcut:hover {
  background: #DFDFDF;
}

.map-overlay .shortcut .shortcut-icon {
    margin-top:0;
    margin-bottom:0;
    color:#777;
}

.map-overlay .shortcut.active {
  background-color:transparent;
}
.map-overlay .shortcut .shortcut-label {
  margin: 0 auto;
}

.map-overlay .shortcut.active > i,  .map-overlay .shortcut.active > .shortcut-label {
    color:#F90;
}
  .map-overlay .shortcut:hover {
    background-color:#CCC;
  }

.map-overlay .shortcut.active:hover > .shortcut-icon{
    color:#F90;
}


.map-overlay .well  hr  {
  border-top:1px #BCBCBC solid;
  border-bottom:1px #EFEFEF solid;
  opacity:1;
  margin:3px 0;
}

.map-overlay th {
  vertical-align: bottom;
}

.map-overlay .table-condensed td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
}


.map-overlay .btn-group button {
  font-size:10pt;
}

.map-overlay .btn-group li {
  font-size:9pt;
}




.map-overlay .form label {
  font-size:9pt;
  line-height:10px;
  vertical-align: middle;
  padding-top:2px;

}
.map-overlay .form-horizontal .control-label {
  width:102px;
  font-weight:normal;
  float: left;
  padding-top: 8px;
}


.map-overlay .form-horizontal .lidar-control-row {
  padding-top:4px;
  padding-bottom: 4px;
}

.map-overlay .form-horizontal .lidar-control-row label {
  font-weight:bold;
  float:left;
  font-size:9pt;
  line-height:10px;
  vertical-align: middle;
  padding-right:6px;
  padding-top:8px;
}

.map-overlay .form-horizontal .lidar-control-row .small-label {
  font-weight:normal;
}

.map-overlay .form .form-control, .map-overlay .form .input-group-addon  {
  font-size:12px;
  height:26px;
  padding: 2px 8px;
}

.map-overlay .form select.form-control {
  padding: 0 0 0 2px;
}

.map-overlay .form select {
  line-height:14px;
  font-size:12px;
  height:24px;
  width:100%;
}

.map-overlay .form-horizontal .form-group {
  margin-top:6px;
  margin-bottom:6px;
  margin-right:8px;
}

.map-overlay .form-horizontal .controls {
  margin-left: 107px;
  margin-right: -5px;
}

.map-overlay .select2-form-group {
  margin-left:0px;
/*width:270px;*/
}

.map-overlay .select2-form-group.has-error{
  color: #b94a48;
}

.map-overlay-hover tr:hover td, .map-overlay-hover tr.active td {
  background-color: #F90;
}

.map-overlay .context-header {
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: bold;
}

.map-overlay .context-header > a.btn,
.map-overlay .context-header > .btn-group {
  margin-top:-4px;
  height:28px;
}

ul.wiring-zones {
  margin-left:0;
  padding-left:0;
}

.wiring-zones li {
  display: block;
}

.wiring-zones .wz-title{
  display:block;
  line-height:20px;
  padding: 4px 5px;
  border-bottom:solid 1px #999;
  font-size:12px;
  font-weight:bold;
  vertical-align:middle;
}

.wiring-zones .wz-title .btn{
  margin-top:-4px;
  height:28px;
}

span.wz-title:hover{
  background-color:#F90;
}

.wiring-zones .children li {
  padding-left:5px;
  cursor: grab;
  padding-top: 4px;
  padding-bottom: 4px;
}

.wiring-zones .children li:active {
  cursor: grabbing;
}

.wiring-zones .children li:hover {
  background-color:#F90;
}

.wiring-zones .children li i:last-child {
  margin-left: 2px;
  margin-right: 8px;
}

.wiring-zones ul.children {
  padding:6px 4px;
  margin-left:0px;
  margin-bottom:10px;
}

.wiring-zones .children li.placeholder {
  border: 1px dashed #777;
  padding-top: 3px;
  padding-bottom: 3px;
}

.map-overlay .small-input {
  width: 36px;
}

.map-overlay .form-horizontal .help-block {
  margin-bottom:0;
  margin-top: 2px;
}

.map-overlay .distance input.form-control {
  padding:2px 4px;
}

.map-overlay .distance .input-group-addon {
  padding:2px 4px;
}

.tooltip-inner hr {
  margin: 3px;
}

.tooltip-text-info {
  color: #CCC;
}

.shortcuts .caret {
  color: #666;
}

.shortcuts .dropdown-menu li {
  text-align: left;
}

.layers-dropdown-container {
    right: 246px;
    border-radius: 2px 2px 0 0;
    width: 150px;
  }

/* base admin addition to allow forcing hover highlighting */
.dropdown-menu > li > a.active {
  color: #FFF;
  background-color: #ff9900;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.dropdown-menu > li > button.hover {
  color: #FFF;
  background-color: #ff9900;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.layers-dropdown-menu {
  top: 100%;
  min-width: 150px;
  margin: 0;
  padding: 0;
  border-radius: 0 0 2px 2px;
  border: none;
}

.layers-dropdown-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.mark-change {
  background-color: rgba(255, 153, 59, 0.25);
  box-shadow: 0 0 12px rgba(255, 153, 59, 0.50);

}
.mark-change-add, .mark-change-remove {
  background-color: white;
  transition: all 0.15s;
  -webkit-transition: all 0.15s ease-in-out;
}
.mark-change-add-active {
  background-color: rgba(255, 153, 59, 0.25);
  box-shadow: 0 0 12px rgba(255, 153, 59, 0.50);

}
.mark-change-remove {
  background-color: rgba(255, 153, 59, 0.25);
  box-shadow: 0 0 12px rgba(255, 153, 59, 0.50);
}

.mark-change-remove-active {
  background-color: white;
}

.btn-default.btn-primary.active {
  text-shadow: none;
}

 td.btn-group  {
   min-width: 74px;
 }

.form-subgroup {
  margin: 0 0 4px 0;
  padding: 0 8px 0 0;
  border:1px solid #ccc;
  border-radius:8px
}


.table-popover {
  max-width: none;
  width: 330px;
}

.table-popover.bump-left {
  margin-left: 35px;
}

.table-popover > .arrow{
  left: 40% !important;
}

.table-popover .popover-content {
  padding: 0;
  width: 100%;
}

.table-popover .popover-content .table {
  margin-bottom: 0;
  background-color: white;
  text-align: center;
}

.table-popover .inner-table th {
  text-align: center;
}

.table-popover .inner-table > th {
  width: 50%;
}

.string-tooltip .table {
  margin-bottom: 0;
}

.string-tooltip .table-compact tr {
  margin: 1px 0;
  text-align: left;
}

.map-overlay .inner-subform {
  margin: 4px 0;
  padding: 0 4px;
  border: 1px solid #ccc;
  border-radius: 8px;
}


.map-overlay .col-xs-12 {
  padding-left: 3px;
  padding-right: 3px;
}


.map-overlay .table-condensed tr > td {
  padding: 2px 1px;
}



.map-overlay .keepout-table {
  max-width: 300px;
  overflow-x: hidden;
}

table.fixed-header {
  margin-bottom: 0;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
}


table.fixed-header thead {
  flex: 1 1 auto;
  display: table-header-group;
}

table.fixed-header tbody {
  flex: 1 1 auto;
  overflow-y: scroll;
}


.map-overlay .keepout-table input {
  padding: 3px;
}

th.distance-cell, td.distance-cell {
  max-width: 52px;
}

.map-overlay .keepout-table .distance-cell div.input-group  {
  max-width: 48px;
}

.map-overlay .keepout-table .btn  {
  padding: 5px 8px;
}


.map-overlay .keepout-table td {
  max-width: none;
}

.icon-column {
  width: 20px;
  text-align: center;
}


.map-overlay .keepout-table td.description, .map-overlay  .keepout-table th.description {
  width: 180px;
  max-width: 180px;
}

.map-overlay .keepout-table tr.context-row :hover {
  background-color: inherit;
}

.overlay-info {
  font-size: 16px;
}

.overlay-uploader {
  background-color: #f5f5f5;
  border: dashed 1px #777;
  border-radius: 5px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.overlay-uploader:hover {
  background-color: #e9e9e9;
}

.overlay-uploader:active {
  background-color: #e0e0e0;
}

.overlay-uploader i {
  font-size: 20px;
  margin-right: 8px;
}

.overlay-uploader div p {
  font-size: 12px;
  margin: 0;
  text-align: start;
  line-height: 16px;
}

.overlay-uploader div p span {
  color: #2D72D2;
}

.overlay-uploader:hover div p span {
  text-decoration: underline;
}

.overlay-uploader div p:last-child {
  font-size: 10px;
  color: #777;
  line-height: 13px;
}

.overlay-icon {
  color: #777;
}

.overlay-list {
  list-style: none;
  overflow-y: scroll;
  padding: 0;
  margin-bottom: 4px;
}

.overlay-list.overlay-list hr {
  margin: 0;
  border-bottom: none;
}

.overlay-item {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: grab;
}

.overlay-item:active {
  cursor: grabbing;
}

.overlay-list .placeholder {
  border: 1px dashed #777;
}

.overlay-item.overlay-item:hover {
  background-color: #f90;
}

.overlay-item.selected {
  background-color: #fc3;
}

.overlay-item-name {
  flex-grow: 1;
  padding-right: 8px;
  align-content: center;
  overflow: hidden;
}

.overlay-item-name p {
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 100%;
  align-content: center;
}

.overlay-item-name input {
  width: 100%;
  height: 100%;
  padding: 4px;
  font-size: inherit;
}

.overlay-item-visibility {
  flex-shrink: 0;
  border: none;
  background: none;
  color: #333
}

.overlay-item-visibility:hover {
  color: #666;
}

.overlay-item-visibility:active {
  color: #999;
}

.overlay-hidden {
  opacity: 0.5;
}

.overlay-item-actions {
  flex-shrink: 0;
}

.overlay-edit {
  padding: 8px 12px;
}

.overlay-edit-opacity label {
  display: flex;
  justify-content: space-between;
  font-weight: normal;
}

.overlay-edit-kml-opacity {
  color: #C87619;
  margin: 0;
}

.gear-icon-button {
  color: #666666;
  font-size: 18px;
  float: right;
  padding: 4px 6px;
}
.gear-icon-button:active {
  background-color: #BEBFC0;

}

.gear-icon-button.active {
  color: #F7991E;
}

.bulk-actions__panel {
  display: flex;
  padding: 16px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.bulk-actions__panel p {
  font-size: 12px;
}

.card--info {
  display: flex;
  padding: 3px 8px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.card--info h5 {
  margin-bottom: 4px;
  line-height: 21px;
}

.card--info p {
  font-size: 12.8px;
  line-height: 16.25px;
}

.context-menu__button--disabled {
  color: #D3D3D3 !important;
  pointer-events: none;
}

.shade-message {
  color: #C87619;
  font-size: 12px;
  margin: 8px 6px;
}
