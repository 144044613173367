.form-control .select2-choice {
    border: 0;
    border-radius: 2px;
}

.form-control .select2-choice .select2-arrow {
    border-radius: 0 2px 2px 0;
}

.form-control.select2-container {
    height: auto !important;
    padding: 0;
}

.form-control.select2-container.select2-dropdown-open {
    border-color: #5897FB;
    border-radius: 3px 3px 0 0;
}

.form-control .select2-container.select2-dropdown-open .select2-choices {
    border-radius: 3px 3px 0 0;
}

.form-control.select2-container .select2-choices {
    border: 0 !important;
    border-radius: 3px;
}

.form-group.has-warning .select2-container .select2-choice,
.form-group.has-warning .select2-container .select2-choices,
.form-group.has-warning .select2-container-active .select2-choice,
.form-group.has-warning .select2-container-active .select2-choices,
.form-group.has-warning .select2-dropdown-open.select2-drop-above .select2-choice,
.form-group.has-warning .select2-dropdown-open.select2-drop-above .select2-choices,
.form-group.has-warning .select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid #C09853 !important;
}

.form-group.has-warning .select2-container .select2-choice div {
    border-left: 1px solid #C09853 !important;
    background: #FCF8E3 !important;
}

.form-group.has-error .select2-container .select2-choice,
.form-group.has-error .select2-container .select2-choices,
.form-group.has-error .select2-container-active .select2-choice,
.form-group.has-error .select2-container-active .select2-choices,
.form-group.has-error .select2-dropdown-open.select2-drop-above .select2-choice,
.form-group.has-error .select2-dropdown-open.select2-drop-above .select2-choices,
.form-group.has-error .select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid #B94A48 !important;
}

.form-group.has-error .select2-container .select2-choice div {
    border-left: 1px solid #B94A48 !important;
    background: #F2DEDE !important;
}

.form-group.has-info .select2-container .select2-choice,
.form-group.has-info .select2-container .select2-choices,
.form-group.has-info .select2-container-active .select2-choice,
.form-group.has-info .select2-container-active .select2-choices,
.form-group.has-info .select2-dropdown-open.select2-drop-above .select2-choice,
.form-group.has-info .select2-dropdown-open.select2-drop-above .select2-choices,
.form-group.has-info .select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid #3A87AD !important;
}

.form-group.has-info .select2-container .select2-choice div {
    border-left: 1px solid #3A87AD !important;
    background: #D9EDF7 !important;
}

.form-group.has-success .select2-container .select2-choice,
.form-group.has-success .select2-container .select2-choices,
.form-group.has-success .select2-container-active .select2-choice,
.form-group.has-success .select2-container-active .select2-choices,
.form-group.has-success .select2-dropdown-open.select2-drop-above .select2-choice,
.form-group.has-success .select2-dropdown-open.select2-drop-above .select2-choices,
.form-group.has-success .select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid #468847 !important;
}

.form-group.has-success .select2-container .select2-choice div {
    border-left: 1px solid #468847 !important;
    background: #DFF0D8 !important;
}
