.expired-modal .plan-header {
    border: 1px solid #DDD;
    border-bottom: 0;
}

.expired-modal .plan .plan-price {
    background: #fff;
    color: #444;
    min-height: 200px;
    text-shadow: none;
}

.expired-modal .plan .plan-price div {
    font-size: 15px;
    margin: 5px 10px;
    line-height: 15px;
}

.expired-modal .plan-price .btn.signup {
    width: 80px;
}

.expired-modal .plan-features li {
    padding: 0.7em 0;
}

.expired-modal .plan.best-price .plan-price {
    padding: 5px 0px;
}

.expired-modal .plan.best-price .plan-price label {
    font-size:13px;
    font-weight:normal;
}

.expired-modal .form-group label,
.expired-modal .form-group .form-control {
    margin:10px 0;
}
