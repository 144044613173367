.row.bordered {
    border-bottom: 1px solid #EEE;
}
.row.bordered.top {
    border-top: 1px solid #EEE;
}
.testimonial {
    padding: 40px 8% 0px 5%;
}

.testimonial img {
    /*margin-top:10px;*/
    float: left;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.testimonial .quote {
    padding-top: 10px;
    padding-left: 120px;
}

.testimonial p {
    /*font-family: 'Helvetica Neue', 'Open Sans', sans-serif;*/
    font-family: 'Helvetica Neue',sans-serif;
    color: #A4A4A4;
    font-size: 16px;
    font-weight: 200;
    line-height: 23px;
    padding-right: 0px;
}

.testimonial .quote .author {
    color:#666;
    font-style: normal;
    padding-left:60px;
    padding:10px;
    text-align:right;
    line-height:18px;
    font-weight:500;
    font-size:14px;

}

.quote {
    margin-bottom:35px;
    margin-left:10px;
    margin-right:10px;
}

.quote .text {
    margin-bottom:5px;
    padding-left:20px;
    line-height:18px;
}

.quote .author {
    color:#9c9c9c;
    /*float:right;*/
    padding-left:60px;
    padding:10px;
    text-align:right;
    line-height:18px;
    font-weight:500;
    /*padding:3px 10px;*/
    /*max-width:65%;*/
}

.pricing .section {
    padding:30px;
    border:none;
    text-align:center;
    position:relative;
}

.pricing .section p{
    z-index: 1000;
}
.pricing .section h3{
    font-size:18px;
}
.pricing .section i {
    color:#f90;
}

.middle-float {
    position:absolute;
    width:100%;
    text-align:center;
    top:10%;
}
.middle-float p {
    font-size:7em;
    color:#EEE;
}

/* new */

.login-center-outer {
  text-align: center;
}

.login-center-inner {
  display: inline-block;
}

.login-header-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 32px;
  font-weight: 600;
  color: #404040;
  letter-spacing: 3.5px;
  line-height: 40px;
}

.login-subheader-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #404040;
  letter-spacing: 2px;
  line-height: 32px;
}

.login-standard-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 28px;
  color: #404040;
}

.login-price-small {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #404040;
}

.login-price-med {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #404040;
}

.login-price-big {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 84px;
  font-weight: 600;
  letter-spacing: 4px;
  color: #404040;
}

.login-text-link {
  cursor: pointer;
  text-decoration: none;
  color: #ff8800;
}

.login-block-spacing {
  margin: 24px 16px;
}

.login-new {
  display: flex;
  padding-top: 15px;
}

.login-new > div {
  display: flex;
}

.login-new .checkbox {
  transform: scale(1.2);
  align-items: center;
}

.login-new .terms {
  max-width: 240px;
  margin-left: 10px;
  text-align: left;
}

.login-new .checkbox label {
  display: inline-block;
  max-width: 160px;
}

.login-new .checkbox input[type='checkbox'] {
  box-shadow: none;
  width: 30px;
}

/* new front page */

.nufront-page-override {
  position: absolute;
  left: 0px;
  top: 0px;
  min-width: 100%;
  min-height: 100%;
  background-color: #ffffff;
  z-index: 99;
}

.nufront .stretch-image-bg {
  box-sizing: border-box;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.nufront .title-bg-container {
  position: static;
  overflow: hidden;
  width: 100%;
  height: auto;
  background-image: url('../../images/title_large.jpg');
}

.nufront .title-container {
  margin-top: 160px;
  margin-bottom: 120px;
  margin-left: 60px;
  margin-right: 18px;
}

@media screen and (max-width: 720px) {
  .nufront .title-container {
    margin-top: 120px;
    margin-bottom: 80px;
    margin-left: 20px;
  }
}

@media screen and (min-width: 1000px) {
  .nufront .title-container {
  margin-top: 180px;
  margin-bottom: 140px;
  margin-left: 100px;
  }
}

.nufront .center-outer {
  text-align: center;
}

.nufront .center-inner {
  display: inline-block;
}

.nufront .flex-row {
  display: flex;
  flex-flow: row wrap;
}

.nufront .flex-col {
  flex: 1;
}

.nufront .title-header-large-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 5px;
  margin-top: 8px;
  line-height: 54px;
}

@media screen and (max-width: 720px) {
  .nufront .title-header-large-text {
    font-size: 32px;
  }
}

@media screen and (min-width: 1000px) {
  .nufront .title-header-large-text {
    font-size: 48px;
  }
}

.nufront .title-subheader-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1.2px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 6px;
}

@media screen and (max-width: 720px) {
  .nufront .title-subheader-text {
    font-size: 14px;
  }
}

@media screen and (min-width: 1000px) {
  .nufront .title-subheader-text {
    font-size: 18px;
  }
}

.nufront .title-subline-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 20px;
  color: #ffffff;
  margin: 15px 20px;
  display: inline-block;
}

.nufront .link-normal {
  cursor: pointer;
  text-decoration: none;
}

.nufront .link-text {
  cursor: pointer;
  text-decoration: none;
  color: #ff8800;
}

.nufront .button-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #ffffff;
  padding: 2px;
}

.nufront .button-orange {
  background-color: #ff8800;
  transition: background-color 0.25s;
}

.nufront .button-orange:hover {
  background-color: #884400;
}

.nufront .button-orange-transparent {
  background-color: rgba(255, 132, 20, 0.8);
  transition: background-color 0.25s;
}

.nufront .button-orange-transparent:hover {
  background-color: rgba(128, 66, 10, 0.8);
}

.nufront .navbar-container {
  position: fixed;
  width: 100%;
  top: 0px;
  padding: 0px;
  margin: 0px;
  z-index: 199;
  background-color: #fff;
}

.nufront .navbar-inner {
  width: 100%;
  height: auto;
  padding-right: 0px;
  padding-left: 24px;
  padding-top: 10px;
  padding-bottom: 12px;
  margin: 0px;
  background-color: #ffffff;
}

.nufront .navbar-menu-ornament {
  display: inline-block;
  background-color: #ff8800;
  overflow: hidden;
  width: 6px;
  height: 6px;
  margin: 2px 2px;
}

.nufront .navbar-menu-link {
  display: inline-block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 1px;
  color: #404040;
  margin: 0px 9px;
  padding-top: 4px;
}

.nufront .section-superheader-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 4px;
  line-height: 48px;
  color: #404040;
}

.nufront .section-header-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 1.2px;
  line-height: 36px;
  color: #404040;
}

.nufront .section-subheader-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 24px;
  color: #404040;
}

.nufront .section-halfheader-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 1.1px;
  line-height: 32px;
  color: #404040;
}

.nufront .section-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 24px;
  color: #404040;
}

.nufront .section-spacing {
  margin: 20px 8px;
}

.nufront .page-limit-large {
  max-width: 960px;
}

.nufront .page-limit-xlarge {
  max-width: 1280px;
}

.nufront .half-section-container {
  padding: 4px 12px;
}

.nufront .release-note-container {
  margin: 24px 18px;
}

.nufront .min-column-container {
  min-width: 320px;
}

.nufront .release-note-header {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.2px;
  line-height: 24px;
  color: #404040;
}

.nufront .release-note-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 24px;
  color: #404040;
}

.nufront .release-note-date {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 24px;
  color: #a8a8a8;
}

.nufront .subheader-box-gray {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.3px;
  color: #ffffff;
  margin: 8px 8px;
  padding: 4px;
  background-color: #404040;
}

.nufront .price-small {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #404040;
}

.nufront .price-big {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 84px;
  font-weight: 600;
  letter-spacing: 4px;
  color: #404040;
  margin: 2px;
}

.nufront .footer-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  font-weight: 300;
  letter-spacing: 0.2px;
  line-height: 15px;
  color: #ffffff;
  padding: 4px 12px;
}

.nufront .copyright-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  font-weight: 300;
  letter-spacing: 0.2px;
  line-height: 15px;
  color: #808080;
  padding: 4px 12px;
}

.nufront .form-control {
  padding: 4px 8px;
}

.nufront .has-error .error-text,
.nufront .has-error .link-text {
  color: #c00000;
  text-decoration: underline;
}
