/*------------------------------------------------------------------

[Pricing Plans Stylesheet]

	Project:		Base Admin
	Version:		2.0
	Last change:	12/29/2012
	Assigned to:	Rod Howard (rh)


-------------------------------------------------------------------*/


/*-- Plan Container --*/

.plan-container {
    position: relative;
    float: left;
}

/*-- Plan --*/

.plan {
    margin-right: 6px;
    margin: 0 10px;

    border-radius: 4px;
    background-color: white;
}


/*-- Plan Header --*/

.plan-header {
    text-align: center;
    color: #FFF;

    background-color: #686868;

    -webkit-border-top-left-radius: 4px;
	-webkit-border-top-right-radius: 4px;
	-moz-border-radius-topleft: 4px;
	-moz-border-radius-topright: 4px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;

    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}

.plan-title {
    padding: 10px 0;

	font-size: 16px;
    color: #FFF;

	border-bottom: 1px solid #FFF;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    border-radius: 4px 4px 0 0;
}

.plan-price {
	padding: 20px 0 10px;

    font-size: 66px;
    line-height: 0.8em;

	background-color: #797979;

    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.plan-price span.description {
    display: block;
    margin-bottom: 0;

    font-size: 13px;
    line-height: 0;
    padding: 0.5em 0 1.5em;
}

.plan-price span.term {
    display: block;
    margin-bottom: 0;

    font-size: 13px;
    line-height: 0;
    padding: 1.5em 0 1em;
}

.plan-price span.note {
	position: relative;
	top: -40px;

	display: inline;

    font-size: 17px;
    line-height: 0.8em;
}

.plan-price span.footnote {
	display: block;

    font-size: 13px;
	line-height: 1.6em;
	margin-top: 1.6em;
}



/*-- Plan Features --*/

.plan-features {
	border: 1px solid #DDD;
	border-bottom: none;
	padding-bottom: 1em;
}

.plan-features ul {
	padding: 0;
	margin: 0;

	list-style: none;
}

.plan-features li {
	padding: 1em 0;
	margin: 0 2em;

	text-align: center;

	border-bottom: 1px dotted #CCC;
}

.plan-features li:last-child {
	border-bottom: none;
}


/*-- Plan Actions --*/

.plan-actions {
	padding: 1.15em 0;

	background: #F2F2F2;

	background-color: whiteSmoke;
	background-image: -moz-linear-gradient(to bottom, #F8F8F8, #E6E6E6);
	background-image: -ms-linear-gradient(to bottom, #F8F8F8, #E6E6E6);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#F8F8F8), to(#E6E6E6));
	background-image: -webkit-linear-gradient(to bottom, #F8F8F8, #E6E6E6);
	background-image: -o-linear-gradient(to bottom, #F8F8F8, #E6E6E6);
	background-image: linear-gradient(to bottom, #F8F8F8, #E6E6E6);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e6e6e6', GradientType=0);
	border-color: #E6E6E6 #E6E6E6 #BFBFBF;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:dximagetransform.microsoft.gradient(enabled=false);

	border: 1px solid #DDD;

	-webkit-border-bottom-right-radius: 4px;
	-webkit-border-bottom-left-radius: 4px;
	-moz-border-radius-bottomright: 4px;
	-moz-border-radius-bottomleft: 4px;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}

.plan-actions .btn {
	padding: 1em 0;
	margin: 0 2em;

	display: block;

	font-size: 16px;
	font-weight: 600;
}



/*-- Columns --*/

.pricing-plans.plans-1 .plan-container {
	width: 100%;
}
.pricing-plans.plans-2 .plan-container {
    width: 50%;
}

.pricing-plans.plans-3 .plan-container {
    width: 33.33%;
}

.pricing-plans.plans-4 .plan-container {
    width: 25%;
}








/*-- Best Value Highlight --*/

.plan.best-value .plan-header {
	background-color: #677E30;
}

.plan.best-value .plan-price {
	background-color: #81994D;
}







.plan.skyblue .plan-header {
	background-color: #3D7AB8;
}

.plan.skyblue .plan-price {
	background-color: #69C;
}



.plan.lavendar .plan-header {
	background-color: #754F75;
}

.plan.lavendar .plan-price {
	background-color: #969;
}



.plan.teal .plan-header {
	background-color: #257272;
}

.plan.teal .plan-price {
	background-color: #399;
}




.plan.pink .plan-header {
	background-color: #FF3778;
}

.plan.pink .plan-price {
	background-color: #F69;
}







.plan.black .plan-header {
	background-color: #222;
}

.plan.black .plan-price {
	background-color: #333;
}





.plan.yellow .plan-header {
	background-color: #C69E00;
}

.plan.yellow .plan-price {
	background-color: #E8B900;
}



.plan.purple .plan-header {
	background-color: #4E2675;
}

.plan.purple .plan-price {
	background-color: #639;
}





.plan.red .plan-header {
	background-color: #A40000;
}

.plan.red .plan-price {
	background-color: #C00;
}



.plan.orange .plan-header {
	background-color: #D98200;
}

.plan.orange .plan-price {
	background-color: #F90;
}



.plan.blue .plan-header {
	background-color: #0052A4;
}

.plan.blue .plan-price {
	background-color: #06C;
}




/*-- Green Plan --*/

.plan.green .plan-header {
	background-color: #677E30;
}

.plan.green .plan-price {
	background-color: #81994D;
}





/*------------------------------------------------------------------
[2. Min Width: 767px / Max Width: 979px]
*/

@media (min-width: 767px) and (max-width: 979px) {

	.pricing-plans .plan-container {
	    width: 50% !important;
	    margin-bottom: 2em;
	}

}



@media (max-width: 767px) {

	.pricing-plans .plan-container {
	    width: 100% !important;
	    margin-bottom: 2em;
	}

}
