.intro .fa-ul {
    margin-left:0;
    padding-left:25px;
}

.intro .fa-ul

.service {
    text-align:center;
}
.service .quote {
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: 200;
    font-size: 1.1em;
    color: #777;
    text-align: center;
    /*font-size:110%;*/
}

.intro a:hover {
    text-decoration:none;
}
